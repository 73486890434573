<template>
    <div class="tiw__qrPanel">
        <div class="tiw__qrPanel__body">
            <h5 class="tiw__qrPanel__title">
                {{ useTranslate("tiw__qrPanel__title") }}
            </h5>
            <p class="tiw__text tiw__qrPanel__text">
                {{ useTranslate("tiw__qrPanel__info") }}
            </p>
        </div>
        <figure class="tiw__qrPanel__figure">
            <img
                class="tiw__qrPanel__image"
                :src="props.qrPreview || ''"
                alt="QR Code"
            />
        </figure>
    </div>
</template>

<script lang="ts">
export default {
    name: "QrPanelComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [QR Panel]
 * Плитка QR-кода
 */

// Types
import type { I_QRPanel } from "./qrPanel.types.ts";

// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

const props = defineProps<I_QRPanel>();
</script>

<style lang="scss" src="./qrPanel.scss"></style>
