import Bugsnag from "@bugsnag/js";

export enum BugTrackerSection {
    INFO = "Info data",
    WARNING = "Warning data",
    ERROR = "Error data",
}

export enum BugTrackerType {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

export const useBugTracker = (
    message: string,
    section: BugTrackerSection,
    payload: Record<string, unknown> | null = null,
) => {
    try {
        let type: BugTrackerType = BugTrackerType.ERROR;

        if (section === BugTrackerSection.INFO) {
            type = BugTrackerType.INFO;
        }

        if (section === BugTrackerSection.WARNING) {
            type = BugTrackerType.WARNING;
        }

        if (section === BugTrackerSection.ERROR) {
            type = BugTrackerType.ERROR;
        }

        Bugsnag.notify(message, (e) => {
            if (e.errors && e.errors.length && e.errors.length > 0) {
                e.errors[0].errorClass = type;
            }

            e.severity = type;
            e.addMetadata(section, payload);
        });
    } catch (e) {
        console.log("useBugTracker - exception", e);
    }
}
