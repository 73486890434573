// Types
import type { I_apiGetImageConverted } from "./apiGetImageConverted.types";

/** Получить Бренды / Категории */
const apiGetImageConverted: I_apiGetImageConverted = async (url: string) => {
    const fetchData = await fetch(url);
    const blob: Blob = await fetchData.blob();

    return blob;
};

export default apiGetImageConverted;
