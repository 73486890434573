<template>
    <div class="tiw__rolledStatus">
        <div class="tiw__rolledStatus__icons">
            <TimeIcon v-if="props.status === 'WAITING'" />
            <GearIcon v-if="props.status === 'GRADING'" />
            <CheckIcon v-if="props.status === 'PROCESSED'" />
        </div>
        <div class="tiw__rolledStatus__status">
            {{ props.header }}
        </div>
        <ExternalIcon
            class="tiw__rolledStatus__rollUp"
            @click="TIWStore.rollUpWidget"
        />
        <div
            class="tiw__rolledStatus__loader"
            :style="{ width: props.percent + '%' }"
        ></div>
    </div>
</template>

<script lang="ts">
export default {
    name: "RolledStatus",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [RolledStatus]
 * Свернутый статус обработки фотографий оператором
 */

// Types
import type { I_RolledStatus } from "./rolledStatus.types.ts";

// Icons
import TimeIcon from "@/ui/icons/time.vue";
import GearIcon from "@/ui/icons/gear.vue";
import CheckIcon from "@/ui/icons/check.vue";
import ExternalIcon from "@/ui/icons/external.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";

const TIWStore = useTIWStore();

const props = defineProps<I_RolledStatus>();
</script>

<style lang="scss" src="./rolledStatus.scss"></style>
