<template>
    <label class="tiw__checkbox">
        <input
            type="checkbox"
            name="personal"
            class="tiw__checkbox__input"
            hidden
            v-bind="$attrs"
            @change="
                $emit(
                    'update:modelValue',
                    ($event.target as HTMLInputElement).checked
                )
            "
        />
        <div class="tiw__checkbox__mark">
            <CheckIcon variant="basic" />
        </div>
        <div class="tiw__checkbox__text">
            <slot></slot>
        </div>
    </label>
</template>

<script lang="ts">
export default {
    name: "CheckboxComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Checkbox]
 * Чекбокс
 */

// Components

// Icon
import CheckIcon from "@/ui/icons/check.vue";

// Types
// import type { I_Checkbox } from "./checkbox.types";

// const props = withDefaults(defineProps<Partial<I_Checkbox>>(), {
//     modelValue: false,
// });
</script>

<style lang="scss" src="./checkbox.scss"></style>
