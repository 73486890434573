import { computed, ref } from "vue";
import { defineStore } from "pinia";

/**
 * * 🆂🆃🅾🆁🅴 [ImageFullScreen store]
 * */
export const useImageFullScreenStore = defineStore("ImageFullScreen", () => {
    const image = ref<string>("");
    const visible = ref<boolean>(false);

    const isShown = computed<boolean>(() => visible.value);
    const isHidden = computed<boolean>(() => !visible.value);
    const imageUrl = computed<string>(() => image.value);

    const show = () => {
        visible.value = true;
    };

    const hide = () => {
        image.value = "";
        visible.value = false;
    };

    const setImageUrl = (url: string) => {
        image.value = url;
    };

    return {
        image,
        visible,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        isShown,
        isHidden,
        imageUrl,

        // * 🅼🅴🆃🅷🅾🅳🆂
        setImageUrl,
        show,
        hide,
    };
});
