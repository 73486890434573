import type { I_GRADES } from "@/stores/howYouRateDevice/howYouRateDevice.model";

const GRADE_NORMALIZED: Record<string, I_GRADES> = {
    "A+": "Q",
};

export function deviceGradeNormalize(grade: string): I_GRADES {
    const gradeUpperCase: string = String(grade).toUpperCase();

    return (GRADE_NORMALIZED[gradeUpperCase] ?? gradeUpperCase) as I_GRADES;
}

export function deviceGradeIsEquals(grade1: string, grade2: string): boolean {
    const grade1Normalized: string = deviceGradeNormalize(grade1);
    const grade2Normalized: string = deviceGradeNormalize(grade2);

    return (grade1Normalized === grade2Normalized);
}
