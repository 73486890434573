<template>
    <div v-if="ImageFullScreenStore.isShown" class="tiw__imageFullScreen">
        <img
            class="tiw__imageFullScreen__image"
            :src="ImageFullScreenStore.imageUrl"
            alt="Image"
        />
        <span class="tiw__imageFullScreen__close" @click="onClickClose">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M8 8.00005L25.0323 25.0323" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 25.0323L25.0323 8.00001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </span>
    </div>
</template>

<script lang="ts">
export default {
    name: "ImageFullScreen",
};
</script>

<script setup lang="ts">/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [ImageFullScreen]
 * Полноэкранный просмотр изображения
 */

import { useImageFullScreenStore } from "@/stores/imageFullScreen/imageFullScreen";

const ImageFullScreenStore = useImageFullScreenStore();

const onClickClose = () => {
    ImageFullScreenStore.hide();
};
</script>

<style lang="scss" src="./imageFullScreen.scss"></style>
