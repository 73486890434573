// Hooks
import it4RegisterProduct from "@/hooks/requests/it4RegisterProduct/it4RegisterProduct";
import it4SendAnswerQuestion from "@/hooks/requests/it4SendAnswerQuestion/it4SendAnswerQuestion";
import useTradeInCalculation from "@/hooks/useTradeInCalculation/useTradeInCalculation";
import it4UpdateIMEI from "@/hooks/requests/it4UpdateIMEI/it4UpdateIMEI";
import it4GetStatusQuestion from "@/hooks/requests/it4GetStatusQuestion/it4GetStatusQuestion";
import useQRPath from "@/hooks/useQRPath/useQRPath";
import it4GetLoadPhotoFields from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import { deviceGradeNormalize } from "@/helpers/deviceGrade";

// Data
import { GRADES_DATA } from "@/stores/howYouRateDevice/howYouRateDevice.model";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";

export default async function crazyInit(): Promise<void> {
    const TIWStore = useTIWStore();
    const ChooseDeviceStore = useChooseDeviceStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();

    // ---------------- "ChooseDevice"

    ChooseDeviceStore.crazyInit = true;

    ChooseDeviceStore.field_brand.value = { brand_id: 1, name: "Apple" };
    ChooseDeviceStore.field_brand.data = [
        { brand_id: 1, name: "Apple" },
        { brand_id: 2, name: "Google" },
        { brand_id: 3, name: "Honor" },
        { brand_id: 4, name: "Huawei" },
        { brand_id: 5, name: "Oneplus" },
        { brand_id: 6, name: "Oppo" },
        { brand_id: 7, name: "Realme" },
        { brand_id: 8, name: "Samsung" },
        { brand_id: 9, name: "Vivo" },
        { brand_id: 10, name: "Xiaomi" },
    ];

    ChooseDeviceStore.field_category.value = { category_id: 5, name: "iPhone" };
    ChooseDeviceStore.field_category.data = [
        { category_id: 1, name: "AirPods" },
        { category_id: 2, name: "Apple Watch" },
        { category_id: 3, name: "Mac" },
        { category_id: 4, name: "iPad" },
        { category_id: 5, name: "iPhone" },
    ];

    ChooseDeviceStore.field_model.value = {
        name: "iPhone 11 Pro Max",
        model_id: 33,
        brand_id: 1,
        category_id: 5,
    };
    ChooseDeviceStore.field_model.data = [
        { name: "iPhone 11", model_id: 31, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 11 Pro",
            model_id: 32,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 11 Pro Max",
            model_id: 33,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 12", model_id: 34, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 12 Pro",
            model_id: 35,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 12 Pro Max",
            model_id: 36,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 12 mini",
            model_id: 37,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 13", model_id: 38, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 13 Pro",
            model_id: 39,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 13 Pro Max",
            model_id: 40,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 13 mini",
            model_id: 41,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 14", model_id: 42, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 14 Plus",
            model_id: 43,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 14 Pro",
            model_id: 44,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 14 Pro Max",
            model_id: 45,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 7", model_id: 46, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 7 Plus",
            model_id: 47,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 8", model_id: 48, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 8 Plus",
            model_id: 49,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone SE Gen.2",
            model_id: 50,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone SE Gen.3",
            model_id: 51,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone X", model_id: 52, brand_id: 1, category_id: 5 },
        { name: "iPhone Xr", model_id: 53, brand_id: 1, category_id: 5 },
        { name: "iPhone Xs", model_id: 54, brand_id: 1, category_id: 5 },
        {
            name: "iPhone Xs Max",
            model_id: 55,
            brand_id: 1,
            category_id: 5,
        },
        { name: "iPhone 15", model_id: 579, brand_id: 1, category_id: 5 },
        {
            name: "iPhone 15 Plus",
            model_id: 580,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 15 Pro",
            model_id: 581,
            brand_id: 1,
            category_id: 5,
        },
        {
            name: "iPhone 15 Pro Max",
            model_id: 582,
            brand_id: 1,
            category_id: 5,
        },
    ];

    ChooseDeviceStore.field_other.value = {
        product_id: 768,
        brand_id: 1,
        category_id: 5,
        model_id: 33,
        code: "MWHQ2",
        name: "iPhone 11 Pro Max 512 GB Gold (MWHQ2)",
        color: "Gold",
        variable: "512 GB",
        specialKey1: null,
        specialKey2: null,
    };
    ChooseDeviceStore.field_other.data = [
        {
            product_id: 760,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHG2",
            name: "iPhone 11 Pro Max 64 GB Gold (MWHG2)",
            color: "Gold",
            variable: "64 GB",
            specialKey1: null,
            specialKey2: null,
        },
        {
            product_id: 764,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHL2",
            name: "iPhone 11 Pro Max 256 GB Gold (MWHL2)",
            color: "Gold",
            variable: "256 GB",
            specialKey1: null,
            specialKey2: null,
        },
        {
            product_id: 768,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHQ2",
            name: "iPhone 11 Pro Max 512 GB Gold (MWHQ2)",
            color: "Gold",
            variable: "512 GB",
            specialKey1: null,
            specialKey2: null,
        },
    ];

    ChooseDeviceStore.field_color.value = {
        product_id: 769,
        brand_id: 1,
        category_id: 5,
        model_id: 33,
        code: "MWHR2",
        name: "iPhone 11 Pro Max 512 GB Midnight Green (MWHR2)",
        color: "Midnight Green",
        variable: "512 GB",
        specialKey1: null,
        specialKey2: null,
    };
    ChooseDeviceStore.field_color.data = [
        {
            product_id: 768,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHQ2",
            name: "iPhone 11 Pro Max 512 GB Gold (MWHQ2)",
            color: "Gold",
            variable: "512 GB",
            specialKey1: null,
            specialKey2: null,
        },
        {
            product_id: 769,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHR2",
            name: "iPhone 11 Pro Max 512 GB Midnight Green (MWHR2)",
            color: "Midnight Green",
            variable: "512 GB",
            specialKey1: null,
            specialKey2: null,
        },
        {
            product_id: 770,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHP2",
            name: "iPhone 11 Pro Max 512 GB Silver (MWHP2)",
            color: "Silver",
            variable: "512 GB",
            specialKey1: null,
            specialKey2: null,
        },
        {
            product_id: 771,
            brand_id: 1,
            category_id: 5,
            model_id: 33,
            code: "MWHN2",
            name: "iPhone 11 Pro Max 512 GB Space Gray (MWHN2)",
            color: "Space Gray",
            variable: "512 GB",
            specialKey1: null,
            specialKey2: null,
        },
    ];

    ChooseDeviceStore.field_imei.value = "353911100329243";

    const registerData = await it4RegisterProduct({
        article: TIWStore.INJECTED_GOOD.article, // артикул устройства со страницы партнера
        code: "MWHR2", // 11 Pro Max Midnight Green 512 Gb
        lang: TIWStore.standardizedLocale, // "ru"
        token: "d7bd115c-e845-4381-8f11-8044cbc11dfe", // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
    });

    HowYouRateDeviceStore.registeringDeviceData = registerData;

    ChooseDeviceStore.deviceImagePreview =
        registerData.tradeinThumbnail ||
        "https://mw2.breezyx.space//img/ic_phone.png";

    // ---------------- "HowYouRateDevice"

    // Вопрос для экрана "В каком состоянии ваше устройство?"
    await it4GetStatusQuestion({
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        token: "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        lang: TIWStore.standardizedLocale,
        from: 3,
    });

    // Ответить на вопрос о статусах оценки
    const answerQuestionData = await it4SendAnswerQuestion({
        token: "d7bd115c-e845-4381-8f11-8044cbc11dfe", // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
        lang: TIWStore.standardizedLocale,
        c: "240806092840861306", // ["240806092840861306" - defects ], ["240806092553996382" - like new]
        ans: "",
        questionId: "240806085349802211",
        from: "3",
    });

    // Устанавливаем значение грейда из ответа ответа на вопрос
    HowYouRateDeviceStore.grade = deviceGradeNormalize(answerQuestionData.device.grade);

    // Устанавливаем описание значения грейда "Как новый", "С дефектами"
    HowYouRateDeviceStore.rate = useTranslate(
        `tiw__grades__${
            GRADES_DATA[answerQuestionData.device.grade].gradeCode
        }_stateInfo`
    );

    useTradeInCalculation({
        tradeInPrice: answerQuestionData.device.price,
    });

    // ---------------- "EnterImei"

    // Здесть без GET... т.к. IMEI заведомо верный для выбранного девайса
    await it4UpdateIMEI({
        article: TIWStore.INJECTED_GOOD.article, // артикул устройства со страницы партнера
        token: "d7bd115c-e845-4381-8f11-8044cbc11dfe", // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        imei: ChooseDeviceStore.field_imei.value, // IMEI устройства
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
    });

    await useQRPath();

    const loadPhotoFieldsData = await it4GetLoadPhotoFields({
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        token: "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        lang: TIWStore.standardizedLocale,
    });

    DownloadPhotosStore.fieldsLineData = loadPhotoFieldsData;
}
