// Store
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useBuyDeviceStore } from "@/stores/buyDevice/buyDevice";

// Hooks
import apiGetDeps from "@/hooks/requests/apiGetDeps/apiGetDeps";

/** Загрузить Бренды */
export default async function useBrands(): Promise<void> {
    const ChooseDeviceStore = useChooseDeviceStore();
    const BuyDeviceStore = useBuyDeviceStore();

    const brandsData = await apiGetDeps();
    // console.log(brandsData.data.brands);

    // ChooseDeviceStore

    ChooseDeviceStore.field_brand.data = brandsData.data.brands;

    ChooseDeviceStore.manualCharacteristicsData.brand = brandsData.data.brands;
    ChooseDeviceStore.manualCharacteristicsData.brand_categories =
        brandsData.data.brands_categories;
    ChooseDeviceStore.manualCharacteristicsData.category =
        brandsData.data.categories;

    // BuyDeviceStore

    BuyDeviceStore.field_brand.data = brandsData.data.brands;

    BuyDeviceStore.manualCharacteristicsData.brand = brandsData.data.brands;
    BuyDeviceStore.manualCharacteristicsData.brand_categories =
        brandsData.data.brands_categories;
    BuyDeviceStore.manualCharacteristicsData.category =
        brandsData.data.categories;
}
