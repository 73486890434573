// Hooks
import it4RegisterProduct from "@/hooks/requests/it4RegisterProduct/it4RegisterProduct";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

/** Зарегистрировать сдаваемое устройство в ИТ4 */
export default async function useRegisterProduct(): Promise<void> {
    const TIWStore = useTIWStore();
    const ChooseDeviceStore = useChooseDeviceStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();

    try {
        const registerData = await it4RegisterProduct({
            article: TIWStore.INJECTED_GOOD.article, // артикул устройства со страницы партнера
            code: ChooseDeviceStore.field_color.value.code, // Здесь "field_color", но набор данных один и тот же с полем "field_other", просто это самый последний вариант, "PIXEL2-464WH",
            lang: TIWStore.standardizedLocale, // "ru"
            token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        });

        // useBugTracker("it4RegisterProduct (response)", BugTrackerSection.INFO, {
        //     response: registerData,
        // })

        HowYouRateDeviceStore.registeringDeviceData = registerData;
        // HowYouRateDeviceStore.isNoPhotoSet = !registerData.canWidgetGrading;

        ChooseDeviceStore.deviceImagePreview =
            registerData.tradeinThumbnail || "/img/ic_phone.png";
    } catch (e) {
        useBugTracker("it4RegisterProduct", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                article: TIWStore.INJECTED_GOOD.article,
                code: ChooseDeviceStore.field_color.value.code,
                lang: TIWStore.standardizedLocale,
                token: TIWStore.WIDGET_DATA.token,
            },
        })
    }
}
