<template>
    <div class="tiw__input_with-icon">
        <input
            class="tiw__input"
            type="text"
            v-bind="$attrs"
            :value="props.modelValue"
            @input="
                $emit(
                    'update:modelValue',
                    ($event.target as HTMLInputElement).value
                )
            "
        />
        <Button
            v-if="props.modelValue.length > 3"
            variant="unset"
            type="button"
            @click="$emit('update:modelValue', '')"
        >
            <CloseIcon variant="basic" />
        </Button>
    </div>
</template>

<script lang="ts">
export default {
    name: "InputComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Input]
 * Поле ввода
 */

// Icon
import CloseIcon from "@/ui/icons/close.vue";

// Components
import Button from "@/ui/button/button.vue";

// Types
import type { I_Input } from "./input.types.ts";

const props = withDefaults(defineProps<Partial<I_Input>>(), {
    modelValue: "",
});
</script>

<style lang="scss" src="./input.scss"></style>
