<template>
    <div class="tiw__placeholderLoader" :class="props.classList">
        <div class="tiw__placeholderLoader__block" :class="props.blockClassList" :style="styles"></div>
    </div>
</template>

<script lang="ts">
export default {
    name: "PlaceholderLoader",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Placeholder]
 * Placeholder
 */

// Types
import type { I_PlaceholderLoader } from "./placeholderLoader.types";
import { computed } from "vue";

const props = withDefaults(defineProps<I_PlaceholderLoader>(), {
    width: "100%",
    height: "inherit",
    classList: "",
    blockClassList: "",
});

// Computed
const styles = computed<Record<string, string>>(() => ({
    "--width": props.width,
    "--height": props.height,
}));
</script>

<style lang="scss" src="./placeholderLoader.scss"></style>
