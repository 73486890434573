// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4SendAnswerQuestion,
    I_it4SendAnswerQuestion_Return,
} from "./it4SendAnswerQuestion.types";

/**
 * Ответить на вопрос о статусах оценки устройства "Как новый, хорошее, с дефектами"
 * 8.1 документация ru 1.0.5 (d1b82f5)
 * */
const it4SendAnswerQuestion: I_it4SendAnswerQuestion = async (payload) => {
    const answerQuestionData = await axios.post<
        I_it4SendAnswerQuestion_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/question`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: payload,
        }
    );
    // console.log(answerQuestionData.data);
    return answerQuestionData.data;
};

export default it4SendAnswerQuestion;
