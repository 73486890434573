// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

// Types
import type { I_UseValidate_Return } from "../index";

/** Функция-валидатор для поля "ФИО" */
export default function fioValidator(value: string): I_UseValidate_Return {
    if (!value) {
        return {
            status: false,
            message: useTranslate("tiw__validation__fio_required"),
        };
    }

    // Если длина от 0 до 50 - true
    if (!/^.{6,50}$/gm.test(value)) {
        return {
            status: false,
            message: useTranslate("tiw__validation__fio_range"),
        };
    }

    // Если нашло что-нибудь из символов - true
    if (/[!?;:,'"[\]{}#@$%^&()\\/_*|.`~+=\d]|\s{2}/gm.test(value)) {
        return {
            status: false,
            message: useTranslate("tiw__validation__fio_symbols"),
        };
    }

    return {
        status: true,
        message: useTranslate("tiw__validation__success"),
    };
}
