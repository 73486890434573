/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */

// import { createApp } from "vue";
import * as Vue from "vue";
import { createPinia } from "pinia";
import TIW from "./TradeInWidget.vue";
import { warn } from "@/helpers/consoleColors";

// Bugsnag
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
// import BugsnagPerformance from "@bugsnag/browser-performance";

const pinia = createPinia();

class TIWidget {
    #default_settings = {
        locale: "ru",
        domain: "",
        // theme: "light",
        root_element: "TIWidget",
        mode: "popup", // "popup" | "embed"
    };

    // #default_translations = {
    //     translations: {
    //         ru: {},
    //         en: {},
    //     },
    // };

    #default_api = {
        onInit: null,
        onLoad: null,
        onShow: null,
        onHide: null,
        onCalc: null,
        onCart: null,
        onDrop: null,
        tradeInWithGood: null,
    };

    current_settings = {};
    // current_translations = {};
    current_api = {};

    #init_widget(with_settings) {
        this.current_settings = {
            ...this.#default_settings,
            ...with_settings.settings,
        };

        this.current_api = {
            ...this.#default_api,
            ...with_settings.api,
        };

        Bugsnag.start({
            apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
            plugins: [
                new BugsnagPluginVue(Vue),
            ],
            autoDetectErrors: false,
        })

        /*
        BugsnagPerformance.start({
            apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        })
        //*/

        const bugsnagVue = Bugsnag.getPlugin("vue");

        const app = Vue.createApp(TIW, this.current_settings);

        app.use(pinia);
        app.use(bugsnagVue);
        app.provide("widget", this);
        app.provide("appInstance", app);

        // watch(
        //     pinia.state,
        //     (state) => {
        //         // сохранять все состояние в local storage при каждом его изменении
        //         localStorage.setItem("TIWState", JSON.stringify(state));
        //     },
        //     { deep: true }
        // );

        app.mount("#" + this.current_settings.root_element);
    }

    constructor(init_settings) {
        const { settings: { root_element = '', mode = '' }} = init_settings;
        const cacheKey = (root_element && mode) ? `${root_element}-${mode}` : '';

        if (cacheKey) {
            if (TIWidget.WIDGET_INSTANCE[cacheKey]) {
                console.log("%c[TIWidget] -> RETURN INITIALIZED INSTANCE FROM CACHE", warn);
                return TIWidget.WIDGET_INSTANCE[cacheKey];
            }

            TIWidget.WIDGET_INSTANCE[cacheKey] = this;
        }

        this.#init_widget(init_settings);
    }

    static WIDGET_INSTANCE = {};
}

window.TIWidget = TIWidget;
