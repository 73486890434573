/**
 * Параметры запросов AXIOS
 * @link https://vitejs.dev/guide/env-and-mode#env-variables
 */
export const I_AXIOS = {
    /** TOKEN для fetch-отправки/получения данных */
    SPACE_TOKEN: "2BlYxHqbFwfIjw1gjTtbIZ8l!!AZF38GtA/1MWFIVpxGO2PHMfGw7m714D?z6nE=",

    /** Базовый URL для fetch-отправки/получения данных */
    SPACE_API_URL: import.meta.env.VITE_SPACE_API_URL,

    // |------|--------------------------------------------------|
    // | WARN | Исторически так сложилось ¯\_(ツ)_/¯             |
    // |      | что-то на проде, а что-то ещё на дэве или превью |
    // |------|--------------------------------------------------|

    /**
     * Базовый HOST IT4 для fetch-отправки/получения данных
     * @see it4<request-name>.ts
     */
    STAGE_API_HOST: import.meta.env.VITE_STAGE_API_HOST, // "https://stageapi.it4profit.com",
    /**
     * Базовый HOST PROD IT4 для GET-данных
     * @see downloadPhotos.ts
     */
    IT4_HOST: import.meta.env.VITE_IT4_HOST, // "https://www.it4profit.com",

    /**
     * Базовый HOST PREVIEW IT4 для GET-данных
     * @see chooseDevice.vue
     * @see useQRPath.ts
     * @see evaluationResult.ts
     */
    IT4_HOST_PREVIEW: import.meta.env.VITE_IT4_HOST_PREVIEW, // "https://preview.it4profit.com",

    /**
     * Endpoint на генерацию QR
     * @see useQRPath.ts
     */
    IT4_QR_ENDPOINT: "/tin/upload/photoset.jsp",

    /**
     * API сервиса генерации QR
     * @see useQRPath.ts
     */
    QR_API_IT4: "https://api.it4profit.com/api/various/qr?w=250&h=250&text=",
} as const;
