// Types
import type { I_WIDGET_ANALYTICS_EVENTS } from "./widgetAnalyticsEvents.types";

const WIDGET_ANALYTICS_EVENTS: I_WIDGET_ANALYTICS_EVENTS = {
    TI_MODEL: "ti_model",
    PRE_STATE: "pre_state",
    PRE_PRICE: "pre_price",
    IMEI: "imei",
    ADD_PHOTO: "add_photo",
    PHOTO_ASSESSMENT: "photo_assessment",
    ASSESSMENT_RESULT: "assessment_result",
    PERSONAL_DATA: "personal_data",
    AGREEMENT: "agreement",
    ADD_TO_CART: "add_to_cart",
};

export default WIDGET_ANALYTICS_EVENTS;
