// Libs
import axios from "../configRequest.js";

// Data
import { I_AXIOS } from "../configRequest.model.js";

// Types
import type {
    I_it4CheckContractSign,
    I_it4CheckContractSign_Return,
} from "./it4CheckContractSign.types.js";

/**
 * Проверить подписан ли контракт в ИТ4
 * 16.1 документация ru 1.0.5 (d1b82f5)
 * */
export const it4CheckContractSign: I_it4CheckContractSign = async (payload) => {
    const contractData = await axios.get<I_it4CheckContractSign_Return["data"]>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/signed`,
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: payload,
        }
    );
    // console.log(contractData.data);
    return contractData.data;
};

export default it4CheckContractSign;
