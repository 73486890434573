// Hooks
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import useExecutingStatus from "@/hooks/useExecutingStatus";

// Types
import type { I_OperatorExecuting } from "./operatorExecuting.types";

/**
 * * 🆂🆃🅾🆁🅴 [Operator Executing]
 * */
export const useOperatorExecutingStore = defineStore(
    "operatorExecuting",
    () => {
        // |-------|
        // | STATE |
        // |-------|

        /** Статус обработки фотографий оператором */
        const status = ref<I_OperatorExecuting["status"]>("WAITING");

        /** Статус-процент пройденного времени от обработки фотографий оператором */
        const percent = ref<I_OperatorExecuting["percent"]>(0);

        // |----------|
        // | COMPUTED |
        // |----------|

        /** Текущий статус-текст обработки фотографий оператором */
        const currentStatusMessages = computed(() => {
            return useExecutingStatus(status.value);
        });

        // |---------|
        // | METHODS |
        // |---------|

        /** Сбросить значения STORE на по-умолчанию */
        const restoreToDefault = () => {
            status.value = "WAITING";
            percent.value = 0;
        };

        /** Запросить статус обработки фотографий оператором */
        const checkStatus = async () => {
            if (status.value === "WAITING") {
                status.value = "GRADING";
                return;
            }
            if (status.value === "GRADING") {
                status.value = "PROCESSED";
                return;
            }
        };

        // |-------|
        // | WATCH |
        // |-------|

        return {
            // * 🆂🆃🅾🆁🅴
            status,
            percent,

            // * 🅲🅾🅼🅿🆄🆃🅴🅳
            currentStatusMessages,

            // * 🅼🅴🆃🅷🅾🅳🆂
            restoreToDefault,
            checkStatus,
        };
    }
);
