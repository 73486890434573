<template>
    <vSelect>
        <template #open-indicator>
            <Chevron v-bind="attributes" />
        </template>
        <template #no-options>
            {{ useTranslate("tiw__vselect__notFound") }}
        </template>
    </vSelect>
</template>

<script lang="ts">
export default {
    name: "SelectComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Select]
 * Select / обертка
 */

// Components
import vSelect from "vue-select";

// Hooks
import useTranslate from "~/tiw/hooks/useTranslate/useTranslate";
import { ref } from "vue";

// Icons
import Chevron from "@/ui/icons/chevron.vue";

// ⓘ См. https://vue-select.org/api/slots.html#open-indicator
const attributes = ref({
    ref: "openIndicator",
    role: "presentation",
    class: "vs__open-indicator",
});
</script>
