// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type { I_apiImageConvert, I_apiImageConvert_Return, I_apiImageConvert_ReturnData } from "./apiImageConvert.types";

/** Получить Бренды / Категории */
const apiImageConvert: I_apiImageConvert = async (data: FormData): Promise<I_apiImageConvert_ReturnData> => {
    const fetchData = await axios.postForm<I_apiImageConvert_Return, Promise<I_apiImageConvert_Return>, FormData>(
        I_AXIOS.SPACE_API_URL + "/image/convert",
        data,
    );

    return fetchData.data;
};

export default apiImageConvert;
