// Hooks
import useMeasurementProtocol from "@/hooks/analytics/useMeasurementProtocol";
import useDataLayer from "@/hooks/analytics/useDataLayer";

import WIDGET_ANALYTICS_EVENTS from "@/helpers/widgetAnalyticsEvents/widgetAnalyticsEvents";

/** Отправка аналитики */
export async function useAnalytics(step: string): Promise<void> {
    await Promise.all([
        useMeasurementProtocol(step),
        useDataLayer(step),
    ]);
}

export async function useAnalyticsEventTiModel(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.TI_MODEL);
}

export async function useAnalyticsEventPreState(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.PRE_STATE);
}

export async function useAnalyticsEventPrePrice(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.PRE_PRICE);
}

export async function useAnalyticsEventImei(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.IMEI);
}

export async function useAnalyticsEventAddPhoto(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.ADD_PHOTO);
}

export async function useAnalyticsEventPhotoAssessment(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.PHOTO_ASSESSMENT);
}

export async function useAnalyticsEventAssessmentResult(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.ASSESSMENT_RESULT);
}

export async function useAnalyticsEventPersonalData(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.PERSONAL_DATA);
}

export async function useAnalyticsEventAgreement(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.AGREEMENT);
}

export async function useAnalyticsEventAddToCart(): Promise<void> {
    return useAnalytics(WIDGET_ANALYTICS_EVENTS.ADD_TO_CART);
}
