<template>
    <Modal class="tiw__step__enterImei">
        <template #header>
            <h2>
                {{ useTranslate("tiw__enterImei__title") }}
            </h2>
        </template>

        <div>
            <HandedDevice
                :device-chain="ChooseDeviceStore.deviceChain"
                :device-state="HowYouRateDeviceStore.rate"
                :device-preview="ChooseDeviceStore.deviceImagePreview"
                :device-grade="null"
                :device-no-grade="false"
                :device-terminated="false"
                :on-edit="onEditHandler"
            />
            <form name="enterImeiForm">
                <!-- Поле "IMEI" -->
                <Field :is-error="!EnterImeiValidation.status">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__enterImei__imei") }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="ChooseDeviceStore.field_imei.value"
                            type="text"
                            :placeholder="useTranslate('tiw__enterImei__imei')"
                            name="imei"
                            maxlength="15"
                            inputmode="numeric"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!EnterImeiValidation.status" #hintBottom>
                        <span>
                            {{ EnterImeiValidation.message }}
                        </span>
                    </template>
                </Field>

                <template v-if="loadingToNext">
                    <div class="tiw__step__enterImei__status">
                        <CheckIcon variant="circled" />
                        <span>
                            {{ useTranslate("tiw__enterImei__status") }}
                        </span>
                    </div>
                </template>

                <template v-if="imeiIsNotValid">
                    <div class="tiw__step__enterImei__warn">
                        <div
                            v-if="errorMessage.modelName"
                            class="tiw__step__enterImei__warn__model"
                        >
                            <div
                                class="tiw__step__enterImei__warn__model__title"
                            >
                                {{ useTranslate("tiw__enterImei__yourModel") }}
                            </div>
                            <span
                                class="tiw__step__enterImei__warn__model__phone"
                            >
                                {{ errorMessage.modelName }}
                            </span>
                        </div>
                        {{ errorMessage.error }}
                    </div>
                    <div class="tiw_group tiw_group-sb" :class="{ 'tiw_mb16': foundSignedContract }">
                        <Button
                            color="link"
                            variant="link"
                            type="button"
                            class="tiw_fs-12"
                            @click="showInfoAboutImei"
                        >
                            <span>
                                {{
                                    useTranslate("tiw__enterImei__howFindImei")
                                }}
                            </span>
                        </Button>
                        <Button
                            v-if="errorMessage.modelName"
                            type="button"
                            class="tiw__step__enterImei__btn-close"
                            @click="onEditHandler"
                        >
                            <span>{{ useTranslate("tiw__continue") }}</span>
                        </Button>
                        <Button
                            v-else
                            type="button"
                            class="tiw__step__enterImei__btn-close"
                            @click="clickToClose"
                        >
                            <span>{{ useTranslate("tiw__close") }}</span>
                        </Button>
                    </div>
                </template>
                <template v-else-if="deviceTerminated">
                    <div class="tiw__step__enterImei__warn">
                        {{ useTranslate("tiw__step__enterImei__warn__deviceTerminated") }}
                    </div>
                    <Button
                        type="button"
                        class="tiw__step__enterImei__btn-close"
                        @click="clickToClose"
                    >
                        <span>{{ useTranslate("tiw__close") }}</span>
                    </Button>
                </template>
                <template v-else>
                    <div class="tiw_group tiw_group-sb" :class="{ 'tiw_mb16': foundSignedContract }">
                        <Button
                            color="link"
                            variant="link"
                            type="button"
                            class="tiw_fs-12"
                            @click="showInfoAboutImei"
                        >
                            <span>
                                {{
                                    useTranslate("tiw__enterImei__howFindImei")
                                }}
                            </span>
                        </Button>
                        <Button
                            type="button"
                            class="tiw__step__enterImei__btn-next"
                            :disabled="
                                !ChooseDeviceStore.field_imei.value.length ||
                                !EnterImeiValidation.status ||
                                loadingToNext ||
                                foundSignedContract
                            "
                            :loading="loadingToNext"
                            @click="clickToNext"
                        >
                            <span>
                                {{ useTranslate("tiw__continue") }}
                            </span>
                        </Button>
                    </div>
                </template>

                <template v-if="foundSignedContract">
                    <div class="tiw__step__enterImei__foundSignedContract tiw_mb8">
                        <div class="tiw__step__enterImei__foundSignedContract__title ">
                            {{ useTranslate("tiw__enterImei__imei_foundSignedContract_title") }}
                        </div>
                        <div class="tiw__step__enterImei__foundSignedContract__text" v-html="foundSignedContractText"></div>
                    </div>
                    <div class="tiw__step__enterImei__btn">
                        <Button
                            type="button"
                            color="light"
                            class="tiw__step__enterImei__btn-re-evaluate"
                            @click="onClickReEvaluate"
                        >
                            <span>{{ useTranslate("tiw__enterImei__imei_foundSignedContract_re_evaluate") }}</span>
                        </Button>

                        <Button
                            type="button"
                            class="tiw__step__enterImei__btn-apply-evaluate"
                            @click="onClickApplyEvaluate"
                        >
                            <span>{{ useTranslate("tiw__enterImei__imei_foundSignedContract_apply_evaluate") }}</span>
                        </Button>
                    </div>
                </template>

                <!-- Инфо-блок с пояснением как узнать свой IMEI -->
                <template v-if="howFindImeiBlock">
                    <div class="tiw__step__enterImei__info">
                        <h3 class="tiw__title">
                            {{ useTranslate("tiw__enterImei__howFindImei") }}
                        </h3>
                        <ul class="tiw__ul tiw__ul_disc tiw_fs-14">
                            <li
                                v-html="
                                    useTranslate(
                                        'tiw__enterImei__howFindImei_step1'
                                    )
                                "
                            />
                            <li>
                                {{
                                    useTranslate(
                                        "tiw__enterImei__howFindImei_step2"
                                    )
                                }}
                            </li>
                        </ul>
                        <figure class="tiw_tc">
                            <img
                                src="https://mw2.breezyx.space/img/iPhone-dial-imei.png"
                                alt="iPhone dial *#06#"
                            />
                        </figure>
                    </div>
                </template>
            </form>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "EnterImei",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [EnterImei]
 * Модальное окно / экран "Введите IMEI"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import HandedDevice from "@/components/handedDevice/handedDevice.vue";
import Button from "@/ui/button/button.vue";
import Input from "@/ui/input/input.vue";
import Field from "@/ui/field/field.vue";

// Icon
import CheckIcon from "@/ui/icons/check.vue";

// Hooks
import { ref, computed, watch } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useValidate from "@/hooks/useValidate";
import it4UpdateIMEI from "@/hooks/requests/it4UpdateIMEI/it4UpdateIMEI";
import useQRPath from "@/hooks/useQRPath/useQRPath";
import it4GetLoadPhotoFields from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import it4GetIMEIStatus from "@/hooks/requests/it4GetIMEIStatus/it4GetIMEIStatus";
import it4CheckPhotoSetStatus from "@/hooks/requests/it4CheckPhotoSetStatus/it4CheckPhotoSetStatus";
// import it4CheckContractSign from "@/hooks/requests/it4CheckContractSign/it4CheckContractSign";
import {
    useAnalyticsEventAddPhoto,
    useAnalyticsEventTiModel,
} from "@/hooks/useAnalytics/useAnalytics";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useBugTracker, BugTrackerSection } from "@/helpers/bugTracker";
// import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const DownloadPhotosStore = useDownloadPhotosStore();
// const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();

const onEditHandler = () => {
    HowYouRateDeviceStore.restoreToDefault();
    ChooseDeviceStore.restoreToDefault();
    TIWStore.switchStep("chooseDevice");
    useAnalyticsEventTiModel();
};

const EnterImeiValidation = computed(() => {
    if (!ChooseDeviceStore.field_imei.value.length) {
        return {
            status: true,
            message: "ok",
        };
    }
    const validate = useValidate({
        target: "imei",
        value: ChooseDeviceStore.field_imei.value,
    });
    return validate;
});

const howFindImeiBlock = ref<boolean>(false);
const imeiIsNotValid = ref<boolean>(false);
const deviceTerminated = ref<boolean>(false);
const errorMessage = ref<{ error: string; modelName?: string }>({
    error: "",
    modelName: "",
});
const imeiDataId = ref<string>("");
const previousEvaluationResultPrice = ref<string>("");
const updateInfoDeviceIdForReuse = ref<string>("");
const foundSignedContract = computed<boolean>((): boolean => {
    return updateInfoDeviceIdForReuse.value.length > 0;
});
const foundSignedContractText = computed<string>((): string => {
    const price: string = typeof HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale === "string"
            ? HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale
            : HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale.tradeInSale;

    const currency: string = HowYouRateDeviceStore.registeringDeviceData.currency;

    return useTranslate("tiw__enterImei__imei_foundSignedContract_text")
        .replace("[[PRICE]]", previousEvaluationResultPrice.value || price)
        .replace("[[CURRENCY]]", currency);
});

const showInfoAboutImei = () => (howFindImeiBlock.value = !howFindImeiBlock.value);

const loadingToNext = ref<boolean>(false);

watch(ChooseDeviceStore.field_imei, () => {
    deviceTerminated.value = false;
    updateInfoDeviceIdForReuse.value = "";

    if (
        ChooseDeviceStore.field_imei.value &&
        EnterImeiValidation.value.status
    ) {
        imeiIsNotValid.value = false;
    }
});

const clickToClose = async () => {
    TIWStore.closeWidget();
    useStoreResetter();
    TIWStore.INJECTED_TIW.current_api.onDrop();
};

const interval = ref();

const getImeiExecuter = async (id: string) => {
    try {
        const imeiData = await it4GetIMEIStatus({
            token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
            id, // "c6d7acf4-4657-4b79-b7c2-5e941fc3760d"
        });

        // useBugTracker("EnterImei - it4GetIMEIStatus (response)", BugTrackerSection.INFO, {
        //     response: imeiData,
        // });

        if (imeiData.status === "IN_PROGRESS") return;

        // check updateInfo

        // hack
        imeiData.result.imeiValidation.valid = true;

        if (
            imeiData.result.imeiValidation.valid ||
            imeiData.result.imeiValidation.unavailable
        ) {
            await useQRPath();

            try {
                // Для экрана "Загрузите фото сдаваемого устройства" - загрузить поля для загрузки фото
                const loadPhotoFieldsData = await it4GetLoadPhotoFields({
                    deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                    token: TIWStore.WIDGET_DATA.token,
                    lang: TIWStore.standardizedLocale,
                });

                // useBugTracker("EnterImei - it4GetLoadPhotoFields (response)", BugTrackerSection.INFO, {
                //     response: loadPhotoFieldsData,
                // })

                DownloadPhotosStore.fieldsLineData = loadPhotoFieldsData;
            } catch (e) {
                useBugTracker("EnterImei - it4GetLoadPhotoFields", BugTrackerSection.ERROR, {
                    exception: e.message,
                    request: {
                        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                        token: TIWStore.WIDGET_DATA.token,
                        lang: TIWStore.standardizedLocale,
                    },
                })
            }

            loadingToNext.value = false;

            clearInterval(interval.value);

            ChooseDeviceStore.field_imei.savedValue = ChooseDeviceStore.field_imei.value;

            TIWStore.switchStep("downloadPhotos");
            useAnalyticsEventAddPhoto();
        } else {
            /**
                "1-й экран 'К сожалению устройство не принимается'"
                    "unavailable": !true,
                    "valid": false,
                    "found": false,

                "2-й экран 'Проверьте правильность IMEI'"
                    "unavailable": !true,
                    "modelName": null || '' [1 условие]

                "3-й экран 'Пожалуйста перепройдите проверку'"
                    "unavailable": !true,
                    "valid": false,
                    "found": true,
            */

            if (!imeiData.result.imeiValidation.modelName) {
                console.log("UNCORRECT");
                errorMessage.value = {
                    error: useTranslate("tiw__enterImei__imei_uncorrect"),
                };
            } else {
                if (
                    !imeiData.result.imeiValidation.valid &&
                    !imeiData.result.imeiValidation.found
                ) {
                    console.log("NOT ACCEPTED");
                    errorMessage.value = {
                        error: useTranslate("tiw__enterImei__imei_notAccepted"),
                    };
                }

                if (
                    !imeiData.result.imeiValidation.valid &&
                    imeiData.result.imeiValidation.found
                ) {
                    console.log("RE EVALUATE");
                    errorMessage.value = {
                        error: useTranslate("tiw__enterImei__imei_reEvaluate"),
                        modelName: imeiData.result.imeiValidation.modelName,
                    };
                }
            }
            imeiIsNotValid.value = true;
            loadingToNext.value = false;
            clearInterval(interval.value);
        }
    } catch (e) {
        useBugTracker("EnterImei - it4GetIMEIStatus", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                token: TIWStore.WIDGET_DATA.token,
                id: id,
            },
        })
    }
};

const runIntervalImeiExecuter = () => {
    interval.value = setInterval(async () => {
        await getImeiExecuter(imeiDataId.value);
    }, 5000);
};

const onClickReEvaluate = () => {
    updateInfoDeviceIdForReuse.value = "";
    loadingToNext.value = true;
    runIntervalImeiExecuter();
};

const onClickApplyEvaluate = async () => {
    HowYouRateDeviceStore.registeringDeviceData.deviceId = updateInfoDeviceIdForReuse.value;

    // временно решили, что всегда отправляем клиента на заполнение данных
    TIWStore.switchStep("successfulEvaluation");

    /*
    const ContractSignInfo = await it4CheckContractSign({
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
    });

    if (ContractSignInfo.signed) {
        // отправляем событие onCart
        TIWStore.INJECTED_TIW.current_api.onCart({
            brand: ChooseDeviceStore.brandName,
            device_id: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            device_imei: ChooseDeviceStore.field_imei.value,
            trade_up_model: TIWStore.INJECTED_GOOD.panelTitle,
            trade_up_price: TIWStore.INJECTED_GOOD.panelPrice,
            model: ChooseDeviceStore.deviceChain.join(" "),
            modelData: {
                brand: ChooseDeviceStore.field_brand.value?.name || "",
                category: ChooseDeviceStore.field_category.value?.name || "",
                model: ChooseDeviceStore.field_model.value?.name || "",
                other: ChooseDeviceStore.field_other.value?.variable || "",
                color: ChooseDeviceStore.field_color.value?.color || "",
            },
            price: HowYouRateDeviceStore.calculatedDeviceData.panelCost,
            trade_in_price:
                typeof HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale === "string"
                    ? HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale
                    : HowYouRateDeviceStore.calculatedDeviceData.panelTradeInSale.tradeInSale,
            top_up_price: HowYouRateDeviceStore.calculatedDeviceData.panelActionSale
                ? HowYouRateDeviceStore.calculatedDeviceData.panelActionSale.actionSale
                : null,
            grade: HowYouRateDeviceStore.registeringDeviceData.grade,
            certificate: ContractSignInfo.certificateNumber,
            signedDocumentUrl: ContractSignInfo.signedDocumentUrl,
            final_price: true,
            first_name: SuccessfulEvaluationStore.destructuredFio.firstName,
            last_name: SuccessfulEvaluationStore.destructuredFio.lastName,
            email: SuccessfulEvaluationStore.fields.email,
            phone: SuccessfulEvaluationStore.fields.phone,
        });

        // и закрываем виджет
        TIWStore.closeWidget();
        useStoreResetter();
    } else {
        TIWStore.switchStep("successfulEvaluation");
    }
    */
};

const clickToNext = async () => {
    // Это типо если был шаг "назад" т.к. имей уже был
    // успешно проверен
    if (
        ChooseDeviceStore.field_imei.value ===
        ChooseDeviceStore.field_imei.savedValue
    ) {
        TIWStore.switchStep("downloadPhotos");
        useAnalyticsEventAddPhoto();
        return;
    }

    loadingToNext.value = true;

    try {
        const imeiData = await it4UpdateIMEI({
            article: TIWStore.INJECTED_GOOD.article, // артикул устройства со страницы партнера
            token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
            imei: ChooseDeviceStore.field_imei.value, // IMEI устройства
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
        });

        // useBugTracker("EnterImei - it4UpdateIMEI (response)", BugTrackerSection.INFO, {
        //     exception: imeiData,
        // })

        imeiDataId.value = imeiData.id;

        const updateInfo_Status: string = imeiData.result.updateInfo?.status || "";
        const updateInfo_DeviceId: string = imeiData.result.updateInfo?.deviceIdForReuse || "";
        const updateInfo_PhotosetId: string = imeiData.result.updateInfo?.photosetId || "";

        if (updateInfo_DeviceId && updateInfo_PhotosetId) {
            if (updateInfo_Status === "found_order") {
                // заказ уже есть - устройство не принимаем
                deviceTerminated.value = true;
                return;
            }

            if (updateInfo_Status === "found_but_too_late") {
                runIntervalImeiExecuter();
                return;
            }

            try {
                // есть предыдущая оценка с подписанным договором
                // значит сохраняем предыдущий deviceId из deviceIdForReuse
                // с предварительным запросом статуса фотосета (там есть результат оценки)
                const photoSetStatus = await it4CheckPhotoSetStatus({
                    deviceId: updateInfo_DeviceId,
                    photosetId: updateInfo_PhotosetId,
                    token: TIWStore.WIDGET_DATA.token,
                    lang: TIWStore.standardizedLocale,
                });

                // useBugTracker("EnterImei - it4CheckPhotoSetStatus (response)", BugTrackerSection.INFO, {
                //     response: photoSetStatus,
                // })

                previousEvaluationResultPrice.value = String(photoSetStatus.price);
            } catch (e) {
                useBugTracker("EnterImei - it4CheckPhotoSetStatus", BugTrackerSection.ERROR, {
                    exception: e.message,
                    request: {
                        deviceId: updateInfo_DeviceId,
                        photosetId: updateInfo_PhotosetId,
                        token: TIWStore.WIDGET_DATA.token,
                        lang: TIWStore.standardizedLocale,
                    }
                })
            }

            loadingToNext.value = false;
            updateInfoDeviceIdForReuse.value = updateInfo_DeviceId;

            /*
            if (updateInfo_Status === "found_order") {
                // заказ уже есть - устройство не принимаем
                deviceTerminated.value = true;
            } else if (updateInfo_Status === "found_signed_contract") {
                // есть предыдущая оценка с подписанным договором
                // значит сохраняем предыдущий deviceId из deviceIdForReuse
                // с предварительным запросом статуса фотосета (там есть результат оценки)
                const photoSetStatus = await it4CheckPhotoSetStatus({
                    deviceId: updateInfo_DeviceId,
                    photosetId: updateInfo_PhotosetId,
                    token: TIWStore.WIDGET_DATA.token,
                    lang: TIWStore.standardizedLocale,
                });

                previousEvaluationResultPrice.value = String(photoSetStatus.price);
                updateInfoDeviceIdForReuse.value = updateInfo_DeviceId;
            } else if (updateInfo_Status === "found_contract") {
                // есть оценка устройства, есть договор, но он не подписан
                // то переводим клиента на шаг заполнения своих данных
                TIWStore.switchStep("successfulEvaluation");
            } else {
                // иначе - на дозаполнение данных для договора
                TIWStore.switchStep("successfulEvaluation");
            }
            //*/
        } else {
            runIntervalImeiExecuter();
        }
    } catch (e) {
        useBugTracker("EnterImei - it4UpdateIMEI", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                article: TIWStore.INJECTED_GOOD.article,
                token: TIWStore.WIDGET_DATA.token,
                imei: ChooseDeviceStore.field_imei.value,
                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            },
        });

        loadingToNext.value = false;
    }
};
</script>

<style lang="scss" src="./enterImei.scss"></style>
