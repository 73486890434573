// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

// Types
import type { I_UseValidate_Return } from "../index";

const ACCEPTED_IMAGE_TYPES = {
    "image/jpg": true,
    "image/jpeg": true,
    "image/png": true,
    "image/bmp": true,
    "image/heic": true,
};
const ACCEPTED_IMAGE_MAX_SIZE = 10000000; // ~10мб = 10 000 000 б

/** Функция-валидатор для поля "Файл" */
export default function fileValidator(
    value: HTMLInputElement
): I_UseValidate_Return {
    if (!value.value) {
        return {
            status: false,
            message: useTranslate("tiw__validation__file_choosePhoto"),
        };
    }

    if (
        !ACCEPTED_IMAGE_TYPES[
            value.files[0].type as keyof typeof ACCEPTED_IMAGE_TYPES
        ]
    ) {
        return {
            status: false,
            message: useTranslate("tiw__validation__file_wrongExt"),
        };
    }

    if (value.files[0].size > ACCEPTED_IMAGE_MAX_SIZE) {
        return {
            status: false,
            message: useTranslate("tiw__validation__file_maxSize"),
        };
    }

    return {
        status: true,
        message: useTranslate("tiw__validation__success"),
    };
}
