// Libs
import axios from "../configRequest.js";

// Data
import { I_AXIOS } from "../configRequest.model.js";

// Types
import type {
    I_it4SendContractToSign,
    I_it4SendContractToSign_Return,
} from "./it4SendContractToSign.types.js";

/**
 * Отправить контракт на подпись через ИТ4 что бы отправилась СМСка
 * 16.3 документация ru 1.0.5 (d1b82f5)
 * */
export const it4SendContractToSign: I_it4SendContractToSign = async (
    payload
) => {
    const contractData = await axios.post<
        I_it4SendContractToSign_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/device/sign`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            params: payload,
        }
    );
    // console.log(contractData.data);
    return contractData.data;
};

export default it4SendContractToSign;
