<template>
    <div v-bind="$attrs" :class="classList">
        <div class="tiw__notification__text">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "NotificationBlock",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Notification]
 * Блок с уведомлением
 */

// Types
import type { I_Notification } from "./notification.types";
import { computed } from "vue";

const props = withDefaults(defineProps<I_Notification>(), {
    type: "warning",
});

// Computed
const classList = computed<string[]>((): string[] => {
    return [
        "tiw__notification",
        `tiw__notification__type-${props.type}`,
    ];
})
</script>

<style lang="scss" src="./notification.scss"></style>
