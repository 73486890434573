// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4GetStatusQuestion,
    I_it4GetStatusQuestion_Return,
} from "./it4GetStatusQuestion.types";

/**
 * Получить вопрос о статусах оценки устройства "Как новый, хорошее, с дефектами"
 * 6.1 документация ru 1.0.5 (d1b82f5)
 * */
const it4GetStatusQuestion: I_it4GetStatusQuestion = async (payload) => {
    const statusQuestionData = await axios.get<
        I_it4GetStatusQuestion_Return["data"]
    >(`${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/question`, {
        headers: {
            Accept: "application/json;charset=UTF-8",
        },
        params: payload,
    });
    // console.log(statusQuestionData.data);
    return statusQuestionData.data;
};

export default it4GetStatusQuestion;
