<template>
    <div class="tiw__stepper">
        <p class="tiw__stepper__status">
            {{ props.message }}
        </p>
        <div class="tiw__stepper__steps">
            <div
                :class="[
                    'tiw__stepper__step',
                    `tiw__stepper__step_${stepsActiveClass(1)}`,
                ]"
            >
                <TimeIcon />
            </div>
            <div
                :class="[
                    'tiw__stepper__divider',
                    `tiw__stepper__divider_${stepsActiveClass(2)}`,
                ]"
            ></div>
            <div
                :class="[
                    'tiw__stepper__step',
                    `tiw__stepper__step_${stepsActiveClass(2)}`,
                ]"
            >
                <GearIcon />
            </div>
            <div
                :class="[
                    'tiw__stepper__divider',
                    `tiw__stepper__divider_${stepsActiveClass(3)}`,
                ]"
            ></div>
            <div
                :class="[
                    'tiw__stepper__step',
                    `tiw__stepper__step_${stepsActiveClass(3)}`,
                ]"
            >
                <CheckIcon />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "StepperComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Stepper]
 * Статус обработки фотографий оператором
 */

// Hooks
import { computed } from "vue";

// Types
import type { I_Stepper } from "./stepper.types.ts";

// Icons
import TimeIcon from "@/ui/icons/time.vue";
import GearIcon from "@/ui/icons/gear.vue";
import CheckIcon from "@/ui/icons/check.vue";

const props = defineProps<I_Stepper>();

const stepsActiveClass = computed(() => (activeStep: number) => {
    const steps: Record<I_Stepper["status"], number> = {
        WAITING: 1,
        GRADING: 2,
        PROCESSED: 3,
    };

    if (steps[props.status] >= activeStep) return "active";
    return "";
});
</script>

<style lang="scss" src="./stepper.scss"></style>
