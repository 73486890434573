<template>
    <div class="tiw__calculatedDevice">
        <header class="tiw__calculatedDevice__header">
            <figure class="tiw__calculatedDevice__figure">
                <img
                    class="tiw__calculatedDevice__image"
                    :src="props.calculatedData.panelDevicePicture"
                    alt="Calculated device"
                />
            </figure>

            <div class="tiw__calculatedDevice__body">
                <h4 class="tiw__calculatedDevice__title">
                    <span v-if="TIWStore.CURRENT_STEP === 'howYouRateDevice'">
                        {{ useTranslate("tiw__calculatedDevice__title_pre") }}
                    </span>
                    {{ props.calculatedData.panelTitle }}
                </h4>
                <p class="tiw__calculatedDevice__price">
                    <span class="tiw__calculatedDevice__cost">
                        {{ props.calculatedData.panelCost }}
                        <span>
                            {{
                                HowYouRateDeviceStore.registeringDeviceData
                                    .currency
                            }}
                        </span>
                    </span>
                    <span class="tiw__calculatedDevice__old-price">
                        {{ props.calculatedData.panelPrice }}
                        <span>
                            {{
                                HowYouRateDeviceStore.registeringDeviceData
                                    .currency
                            }}
                        </span>
                    </span>
                </p>
            </div>
        </header>
        <!-- Расчетный список -->
        <div class="tiw__calculatedDevice__price-list">
            <!-- Цена нового устройства -->
            <div class="tiw__calculatedDevice__price-list__row">
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_key"
                >
                    <span>
                        {{
                            useTranslate(
                                "tiw__calculatedDevice__price_list_costNewDevice"
                            )
                        }}
                    </span>
                </div>
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_value"
                >
                    <span>
                        {{
                            props.calculatedData.panelCostOriginal > TIWStore.minOrderPrice ?
                                props.calculatedData.panelPrice :
                                props.calculatedData.panelCost
                        }}
                    </span>
                    <span>
                        {{
                            HowYouRateDeviceStore.registeringDeviceData.currency
                        }}
                    </span>
                </div>
            </div>

            <!-- Скидка в Trade-In -->
            <div v-if="props.calculatedData.panelCostOriginal > TIWStore.minOrderPrice" class="tiw__calculatedDevice__price-list__row">
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_key"
                >
                    <span>
                        {{
                            useTranslate(
                                "tiw__calculatedDevice__price_list_tradeInSale"
                            )
                        }}
                    </span>
                </div>
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_value"
                >
                    <template
                        v-if="
                            typeof props.calculatedData.panelTradeInSale ===
                            'string'
                        "
                    >
                        <span
                            v-if="TIWStore.CURRENT_STEP === 'howYouRateDevice'"
                        >
                            {{
                                useTranslate(
                                    "tiw__calculatedDevice__price_list_tradeInSale_until"
                                )
                                    .replace(
                                        "[[PRICE]]",
                                        props.calculatedData.panelTradeInSale
                                    )
                                    .replace(
                                        "[[CURRENCY]]",
                                        HowYouRateDeviceStore
                                            .registeringDeviceData.currency
                                    )
                            }}
                        </span>
                        <span v-else>
                            <span>
                                {{
                                    props.calculatedData.panelTradeInSale
                                }}&nbsp;
                            </span>
                            <span>
                                {{
                                    HowYouRateDeviceStore.registeringDeviceData
                                        .currency
                                }}
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <span>
                            {{
                                props.calculatedData.panelTradeInSale
                                    .tradeInSale
                            }}&nbsp;
                        </span>
                        <span>
                            {{
                                HowYouRateDeviceStore.registeringDeviceData
                                    .currency
                            }}
                            <span>*</span>
                        </span>
                    </template>
                </div>
            </div>

            <!-- Экстра-бонус -->
            <template
                v-if="
                    props.calculatedData.panelCostOriginal > TIWStore.minOrderPrice
                    && props.calculatedData.panelActionSale
                "
            >
                <div
                    class="tiw__calculatedDevice__price-list__row tiw__calculatedDevice__price-list__row_action-sale"
                >
                    <div
                        class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_key tiw__calculatedDevice__price-list__row__data_key_action"
                    >
                        <figure
                            v-if="
                                props.calculatedData.panelActionSale.iconUrl !==
                                null
                            "
                        >
                            <img
                                :src="
                                    props.calculatedData.panelActionSale.iconUrl
                                "
                                alt=""
                                srcset=""
                            />
                        </figure>
                        <figure
                            v-else
                            v-html="
                                props.calculatedData.panelActionSale.iconSvg
                            "
                        ></figure>

                        <div>
                            <p>
                                {{
                                    useTranslate(
                                        "tiw__calculatedDevice__price_list_additionalSale"
                                    )
                                }}
                            </p>
                            <span>
                                {{
                                    props.calculatedData.panelActionSale
                                        .description
                                }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_value"
                    >
                        <span>
                            {{
                                props.calculatedData.panelActionSale.actionSale
                            }}&nbsp;
                        </span>
                        <span>
                            {{
                                HowYouRateDeviceStore.registeringDeviceData
                                    .currency
                            }}
                        </span>
                    </div>
                </div>
            </template>
            <div
                v-if="props.calculatedData.panelCostOriginal > TIWStore.minOrderPrice"
                class="tiw__calculatedDevice__price-list__row tiw__calculatedDevice__price-list__row_begin-price"
            >
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_key"
                >
                    <span v-if="TIWStore.CURRENT_STEP === 'howYouRateDevice'">
                        {{ useTranslate("tiw__calculatedDevice__title_pre") }}
                    </span>
                    <span v-else>
                        {{ useTranslate("tiw__calculatedDevice__title_fin") }}
                    </span>
                </div>
                <div
                    class="tiw__calculatedDevice__price-list__row__data tiw__calculatedDevice__price-list__row__data_value"
                >
                    <span v-if="TIWStore.CURRENT_STEP === 'howYouRateDevice'">
                        {{
                            useTranslate(
                                "tiw__calculatedDevice__price_list_tradeInSale_from"
                            )
                                .replace(
                                    "[[PRICE]]",
                                    props.calculatedData.panelCost
                                )
                                .replace(
                                    "[[CURRENCY]]",
                                    HowYouRateDeviceStore.registeringDeviceData
                                        .currency
                                )
                        }}
                    </span>
                    <span v-else>
                        <span>{{ props.calculatedData.panelCost }}&nbsp;</span>
                        <span>
                            {{
                                HowYouRateDeviceStore.registeringDeviceData
                                    .currency
                            }}
                        </span>
                    </span>
                </div>
            </div>
            <div
                v-if="typeof props.calculatedData.panelTradeInSale === 'object'"
                class="tiw__calculatedDevice__price-list__row tiw__calculatedDevice__price-list__row_trade-in-info"
            >
                <span>*</span>
                <p>
                    {{ props.calculatedData.panelTradeInSale.tradeInInfo }}
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "CalculatedDevice",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [CalculatedDevice]
 * Плитка покупаемого в Trade-In устройства с расчетом цены
 */

// Types
import type { I_CalculatedDevice } from "./calculatedDevice.types.ts";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";

// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

const TIWStore = useTIWStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();

const props = defineProps<I_CalculatedDevice>();
</script>

<style lang="scss" src="./calculatedDevice.scss"></style>
