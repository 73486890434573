// Hooks
import it4PhotoDownloader from "@/hooks/requests/it4PhotoDownloader/it4PhotoDownloader";
import it4getPhotoSetId from "@/hooks/requests/it4GetPhotoSetId/it4GetPhotoSetId";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

/** Загрузить фото в ИТ4 */
export default async function usePhotoDownloader(
    formRef: HTMLFormElement
): Promise<void> {
    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();

    const payloadFormData: FormData = new FormData(formRef);

    payloadFormData.set("token", TIWStore.WIDGET_DATA.token);
    payloadFormData.set("lang", TIWStore.standardizedLocale);

    const files: FormDataEntryValue[] = payloadFormData.getAll("file");

    payloadFormData.delete("file");

    DownloadPhotosStore.fieldsLineData.forEach((lineData, ind) => {
        if (files[ind] instanceof File) {
            if (files[ind].size) {
                payloadFormData.append("file", files[ind]);
                payloadFormData.append("lineId", lineData.lineId);
            }
        }
    });

    // useBugTracker("usePhotoDownloader (payload)", BugTrackerSection.INFO, {
    //     fieldsLineData: DownloadPhotosStore.fieldsLineData,
    //     files: files.map((file: File) => ({
    //         name: file.name,
    //         size: file.size,
    //         type: file.type,
    //         lastModified: file.lastModified,
    //     })),
    //     payloadFormData: {
    //         lineId: payloadFormData.getAll("lineId"),
    //         file: payloadFormData.getAll("file"),
    //     },
    // });

    try {
        const loadRequestData = await it4PhotoDownloader(
            HowYouRateDeviceStore.registeringDeviceData.deviceId,
            payloadFormData
        );

        // useBugTracker("usePhotoDownloader - it4PhotoDownloader (response)", BugTrackerSection.INFO, {
        //     response: loadRequestData,
        // })

        DownloadPhotosStore.fieldsLineData = loadRequestData;
    } catch (e) {
        useBugTracker("usePhotoDownloader - it4PhotoDownloader", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            },
        })
    }

    try {
        const photoSetData = await it4getPhotoSetId(
            HowYouRateDeviceStore.registeringDeviceData.deviceId,
            {
                token: TIWStore.WIDGET_DATA.token,
                lang: TIWStore.standardizedLocale,
            }
        );

        // useBugTracker("usePhotoDownloader - it4getPhotoSetId (response)", BugTrackerSection.INFO, {
        //     response: photoSetData,
        // })

        DownloadPhotosStore.photoSetData = photoSetData;
    } catch (e) {
        useBugTracker("usePhotoDownloader - it4getPhotoSetId", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                token: TIWStore.WIDGET_DATA.token,
                lang: TIWStore.standardizedLocale,
            },
        })
    }
}
