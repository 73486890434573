// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type { I_apiGetDeps, I_apiGetDeps_Return } from "./apiGetDeps.types";

/** Получить Бренды / Категории */
const apiGetDeps: I_apiGetDeps = async () => {
    const fetchData = await axios.post<I_apiGetDeps_Return>(
        I_AXIOS.SPACE_API_URL + "/calc/deps"
    );
    // console.log(fetchData.data);
    return fetchData.data;
};

export default apiGetDeps;
