<template>
    <template v-if="props.variant === 'circled'">
        <!-- * Иконка 'Галочка внутри круга' -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            :class="['tiw__icon']"
            v-bind="$attrs"
        >
            <rect
                x="0.75"
                y="0.75"
                width="14.5"
                height="14.5"
                rx="7.25"
                stroke="#fff"
                stroke-width="1.5"
            />
            <path
                d="M5.33203 8.10547L7.21765 9.99109L10.9889 6.21985"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
            />
        </svg>
    </template>

    <!-- * Иконка 'Галочка' -->
    <template v-if="props.variant === 'basic'">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            :class="['tiw__icon']"
            v-bind="$attrs"
        >
            <path
                d="M5.43711 8.04878L5.43686 8.049L5.44393 8.05607L7.39393 10.0061L7.5 10.1121L7.60607 10.0061L12.5561 5.05607C13.0975 4.51464 13.9025 4.51464 14.4439 5.05607C14.9854 5.59749 14.9854 6.40251 14.4439 6.94393L8.44393 12.9439C8.17117 13.2167 7.83397 13.35 7.5 13.35C7.16603 13.35 6.82883 13.2167 6.55607 12.9439L3.55607 9.94393C3.01464 9.40251 3.01464 8.59749 3.55607 8.05607C4.10171 7.51043 4.97557 7.52129 5.43711 8.04878Z"
                fill="white"
                stroke-width="0.3"
            />
        </svg>
    </template>
</template>

<script lang="ts">
export default {
    name: "CheckIcon",
};
</script>

<script lang="ts" setup>
interface I_Props {
    variant?: "basic" | "circled";
}

const props = withDefaults(defineProps<I_Props>(), {
    variant: "circled", // "circled" is default
});
</script>
