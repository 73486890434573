/* eslint-disable */

declare global {
    interface Window {
        helpcrunchSettings: any;
        HelpCrunch: any;
        attachEvent: any;
    }
}

/** подключение help crunch chat */
export default function useHelpCrunchChat(): void {
    if (typeof window === "undefined") {
        console.error("Object window not found");
        return;
    }

    // CrunchSettings
    window.helpcrunchSettings = {
        organization: "breezy",
        appId: "bb9a7e17-62a6-40c9-82ff-a7428732f8cf",
        showWidget: false, // if  this field is not sent, or is sent as true, showChatWidget will automatically run after the init
        openWidget: false, // If sent as true, openChat will run after the init
    };

    // initCrunch
    (function (w, d) {
        var hS = w.helpcrunchSettings;
        if (!hS || !hS.organization) {
            return;
        }
        var widgetSrc = "https://embed.helpcrunch.com/sdk.js";
        w.HelpCrunch = function () {
            w.HelpCrunch.q.push(arguments);
        };
        w.HelpCrunch.q = [];
        function r() {
            if (d.querySelector('script[src="' + widgetSrc + '"')) {
                return;
            }
            var s = d.createElement("script");
            s.async = true;
            s.type = "text/javascript";
            s.src = widgetSrc;
            (d.body || d.head).appendChild(s);
        }
        if (d.readyState === "complete" || hS.loadImmediately) {
            r();
        } else if (w.attachEvent) {
            w.attachEvent("onload", r);
        } else {
            w.addEventListener("load", r, false);
        }
    })(window, document);
}
