// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import type { I_apiGetWidget_Data_TranslationsRecord } from "@/hooks/requests/apiGetWidget/apiGetWidget.types";

/** Функция переводчик */
export default function useTranslate(
    key: string,
    asDefault = "_NO-TRANSLATE_"
) {
    const TIWStore = useTIWStore();

    if (TIWStore.WIDGET_DATA === null) return asDefault;

    const availableLocale = TIWStore.WIDGET_DATA.locales.includes(
        TIWStore.INJECTED_TIW.current_settings.locale
    )
        ? TIWStore.INJECTED_TIW.current_settings.locale
        : "ru";

    let translate = asDefault;

    if (TIWStore.WIDGET_DATA.translations[key]) {
        translate = TIWStore.WIDGET_DATA.translations[key][availableLocale as keyof I_apiGetWidget_Data_TranslationsRecord];
    }

    return translate;
}
