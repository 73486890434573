<template>
    <!-- * Иконка 'Breezy Logo' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="16"
        viewBox="0 0 48 16"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <g clip-path="url(#clip0_26875_36833)">
            <path
                d="M5.28158 15.4174L4.78944 14.7285H4.76034H4.24654V15.4174H3.65625V12.9453H4.76034C4.98643 12.9453 5.18207 12.9821 5.3486 13.0549C5.51512 13.1277 5.64306 13.2314 5.7331 13.3659C5.82313 13.5004 5.86781 13.6591 5.86781 13.8428C5.86781 14.0265 5.82245 14.1846 5.73107 14.3178C5.63968 14.451 5.51038 14.5527 5.34318 14.6236L5.91519 15.4181H5.28158V15.4174ZM5.13198 13.5227C5.03991 13.4485 4.90452 13.4111 4.72784 13.4111H4.24654V14.2726H4.72784C4.9052 14.2726 5.03991 14.2352 5.13198 14.1597C5.22404 14.0843 5.27007 13.9786 5.27007 13.8422C5.27075 13.7031 5.22404 13.5968 5.13198 13.5227Z"
                fill="#00C853"
            />
            <path
                d="M17.9727 15.4181V12.9453H18.5167V15.4181H17.9727Z"
                fill="#00C853"
            />
            <path
                d="M19.2461 15.4181V12.9453H19.7477L20.9064 14.4678V12.9453H21.4433V15.4181H20.9806L19.783 13.8461V15.4181H19.2461Z"
                fill="#00C853"
            />
            <path
                d="M16.3502 14.9587V15.4181H12.1133V12.9453H16.3029V13.4047H12.7002V13.9415H16.1465V14.3864H12.7002V14.9587H16.3502Z"
                fill="#00C853"
            />
            <path
                d="M16.668 13.9844H17.4488V14.3748H16.668V13.9844Z"
                fill="#00C853"
            />
            <path
                d="M9.51172 15.4181V12.9453H10.4761C10.8623 12.9453 11.1779 13.0631 11.4228 13.2986C11.6677 13.5317 11.7902 13.8261 11.7902 14.1817C11.7902 14.5349 11.6665 14.8293 11.4193 15.0648C11.1744 15.3003 10.86 15.4181 10.4761 15.4181H9.51172ZM10.0557 14.927H10.4761C10.6998 14.927 10.88 14.8588 11.0166 14.7222C11.1532 14.5832 11.2215 14.4031 11.2215 14.1817C11.2215 13.9627 11.152 13.7837 11.013 13.6447C10.8764 13.5058 10.6975 13.4363 10.4761 13.4363H10.0557V14.927Z"
                fill="#00C853"
            />
            <path
                d="M27.7877 14.9587V15.4181H23.5508V12.9453H27.7404V13.4047H24.1377V13.9415H27.584V14.3864H24.1377V14.9587H27.7877Z"
                fill="#00C853"
            />
            <path
                d="M30.152 14.8885H28.968L28.7419 15.4181H28.1367L29.2733 12.9453H29.8561L30.9968 15.4181H30.3774L30.152 14.8885ZM29.9658 14.454L29.5617 13.5077L29.1575 14.454H29.9658Z"
                fill="#00C853"
            />
            <path
                d="M8.27306 14.8885H7.08909L6.863 15.4181H6.25781L7.39439 12.9453H7.97724L9.11788 15.4181H8.49848L8.27306 14.8885ZM8.0869 14.454L7.68277 13.5077L7.27864 14.454H8.0869Z"
                fill="#00C853"
            />
            <path
                d="M1.73438 15.4181V13.4469H0.921875V12.9453H3.13434V13.4469H2.32183V15.4181H1.73438Z"
                fill="#00C853"
            />
            <path
                d="M31.7231 15.3413C31.5349 15.2899 31.3833 15.2232 31.2695 15.1413L31.4699 14.7243C31.5796 14.7992 31.7089 14.8595 31.8598 14.9052C32.0108 14.9509 32.1611 14.9738 32.3113 14.9738C32.4792 14.9738 32.6031 14.9503 32.683 14.904C32.7629 14.857 32.8035 14.7954 32.8035 14.718C32.8035 14.6609 32.7798 14.6139 32.7324 14.5765C32.685 14.539 32.6241 14.5086 32.5503 14.4857C32.4765 14.4629 32.3763 14.4381 32.2497 14.4108C32.0555 14.3677 31.8964 14.3245 31.7725 14.2813C31.6486 14.2382 31.5423 14.1684 31.4537 14.0732C31.365 13.9773 31.321 13.8497 31.321 13.6904C31.321 13.5514 31.3609 13.4258 31.4415 13.3128C31.5214 13.1998 31.6425 13.111 31.8043 13.0443C31.9661 12.9783 32.1631 12.9453 32.3966 12.9453C32.5591 12.9453 32.7182 12.9637 32.8739 12.9999C33.0296 13.0361 33.1656 13.0888 33.2821 13.1573L33.1 13.5775C32.8644 13.4524 32.6288 13.3896 32.3932 13.3896C32.2281 13.3896 32.1062 13.4143 32.027 13.4645C31.9478 13.5146 31.9086 13.5806 31.9086 13.6625C31.9086 13.7444 31.9539 13.8053 32.0453 13.8453C32.1367 13.8853 32.2755 13.9246 32.4623 13.9634C32.6566 14.0065 32.8157 14.0497 32.9395 14.0928C33.0634 14.136 33.1697 14.2045 33.2584 14.2978C33.3471 14.3911 33.3911 14.5174 33.3911 14.6767C33.3911 14.8132 33.3504 14.9382 33.2692 15.0506C33.188 15.1636 33.0655 15.2524 32.903 15.3191C32.7405 15.3851 32.5422 15.4181 32.3093 15.4181C32.1062 15.4181 31.9113 15.3927 31.7231 15.3413Z"
                fill="#00C853"
            />
            <path
                d="M35.1912 14.542V15.4181H34.6009V14.5348L33.6133 12.9453H34.2401L34.9211 14.0439L35.6021 12.9453H36.1816L35.1912 14.542Z"
                fill="#00C853"
            />
            <path
                d="M41.5286 10.5934H39.4934L36.8711 2.85938H38.9055L41.5286 10.5934Z"
                fill="#3500BE"
            />
            <path
                d="M36.4377 10.5987H30.168V9.03598L34.0598 4.66888H30.4789V2.88281H36.4377V4.44552L32.5459 8.81263H36.4377V10.5987Z"
                fill="#3500BE"
            />
            <path
                d="M11.4295 10.5968H9.41016V2.87109H10.9808L11.4295 4.27708V10.5968Z"
                fill="#3500BE"
            />
            <path
                d="M5.15897 10.7528C3.5414 10.7528 1.81641 8.95155 1.81641 6.7377C1.81641 4.52386 3.5414 2.72266 5.15897 2.72266C7.3712 2.72266 8.94791 4.52386 8.94791 6.7377C8.94791 8.95155 7.3712 10.7528 5.15897 10.7528ZM4.93578 4.51478C3.83117 4.51478 2.9316 5.51191 2.9316 6.7377C2.9316 7.96349 3.83042 8.96063 4.93578 8.96063C6.04114 8.96063 6.93995 7.96349 6.93995 6.7377C6.93919 5.51267 6.04038 4.51478 4.93578 4.51478Z"
                fill="#3500BE"
            />
            <path
                d="M2.48345 10.5972H0.921875V0.554688H2.92983V9.04436L2.48345 10.5972Z"
                fill="#3500BE"
            />
            <path
                d="M11.4308 6.73771H10.9844C10.9844 4.47465 11.1728 2.72266 13.4349 2.72266H13.9297V4.73208H13.4349C12.3038 4.73283 11.4308 5.60656 11.4308 6.73771Z"
                fill="#3500BE"
            />
            <path
                d="M30.1695 7.51906C30.1695 7.51906 30.2157 7.06933 30.2157 6.7377C30.2157 5.66561 29.798 4.65712 29.0407 3.89848C28.2826 3.14059 27.2756 2.72266 26.2035 2.72266C25.1322 2.72266 24.1245 3.14059 23.3664 3.89848C22.609 4.65712 22.1914 5.66485 22.1914 6.7377C22.1914 7.8098 22.609 8.81829 23.3664 9.57693C24.1245 10.3348 25.1315 10.7528 26.2035 10.7528C27.2749 10.7528 28.2826 10.3348 29.0407 9.57693L27.5994 8.32994C27.2385 8.71986 26.7468 8.96063 26.2043 8.96063C25.0997 8.96063 24.2001 7.96349 24.2001 6.7377C24.2001 5.51191 25.0989 4.51478 26.2043 4.51478C27.3097 4.51478 28.2085 5.51191 28.2085 6.7377C28.2085 7.0133 28.1608 7.27526 28.0783 7.51906H30.1695Z"
                fill="#3500BE"
            />
            <path
                d="M29.0751 5.95703H23.3281V7.51974H29.0751V5.95703Z"
                fill="#3500BE"
            />
            <path
                d="M21.7945 7.51906C21.7945 7.51906 21.8407 7.06933 21.8407 6.7377C21.8407 5.66561 21.423 4.65712 20.6657 3.89848C19.9076 3.14059 18.9006 2.72266 17.8285 2.72266C16.7572 2.72266 15.7495 3.14059 14.9914 3.89848C14.234 4.65712 13.8164 5.66485 13.8164 6.7377C13.8164 7.8098 14.234 8.81829 14.9914 9.57693C15.7495 10.3348 16.7565 10.7528 17.8285 10.7528C18.8999 10.7528 19.9076 10.3348 20.6657 9.57693L19.2244 8.32994C18.8635 8.71986 18.3718 8.96063 17.8293 8.96063C16.7247 8.96063 15.8251 7.96349 15.8251 6.7377C15.8251 5.51191 16.7239 4.51478 17.8293 4.51478C18.9347 4.51478 19.8335 5.51191 19.8335 6.7377C19.8335 7.0133 19.7858 7.27526 19.7033 7.51906H21.7945Z"
                fill="#3500BE"
            />
            <path
                d="M20.7001 5.95703H14.9531V7.51974H20.7001V5.95703Z"
                fill="#3500BE"
            />
            <path
                d="M39.8637 15.9992C40.7161 15.9992 41.4072 15.3077 41.4072 14.4547C41.4072 13.6017 40.7161 12.9102 39.8637 12.9102C39.0113 12.9102 38.3203 13.6017 38.3203 14.4547C38.3203 15.3077 39.0113 15.9992 39.8637 15.9992Z"
                fill="#3500BE"
            />
            <path
                d="M45.1428 0.541347L43.7371 0L40.0586 11.9058L40.679 12.1451L41.3001 12.3836L46.5485 1.08269L45.1428 0.541347Z"
                fill="#3500BE"
            />
        </g>
        <defs>
            <clipPath id="clip0_26875_36833">
                <rect
                    width="46.1538"
                    height="16"
                    fill="white"
                    transform="translate(0.921875)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts">
export default {
    name: "BreezyIcon",
};
</script>
