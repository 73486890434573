import { info } from "@/helpers/consoleColors";
import { useAnalyticsStore } from "@/stores/analytics/analytics";

declare global {
    interface Window {
        dataLayer: object[];
    }
}

export default async function useDataLayer(step: string = "unknown"): Promise<boolean> {
    const {
        PROD_MODE,
        EVENT_NAME,
    } = useAnalyticsStore();

    if (typeof window === "undefined") {
        console.error("Object window not found");
        return false;
    }

    const data: object = {
        event: EVENT_NAME,
        step: step,
    };

    console.log('%c[ANALYTICS] DataLayer', info, data);

    if (PROD_MODE) {
        window.dataLayer = window?.dataLayer ?? [];
        window.dataLayer.push(data);
    }

    return true;
}
