<template>
    <template v-if="props.as === 'button'">
        <button v-bind="$attrs" :class="classes">
            <span class="tiw__button__loader" />
            <slot />
        </button>
    </template>

    <template v-if="props.as === 'span'">
        <span v-bind="$attrs" :class="classes">
            <span class="tiw__button__loader" />
            <slot />
        </span>
    </template>

    <template v-if="props.as === 'div'">
        <div v-bind="$attrs" :class="classes">
            <slot />
        </div>
    </template>
</template>

<script lang="ts">
export default {
    name: "ButtonComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Button]
 * Кнопка
 *
 * @info вместо "component :is" сделано специально через "template"
 */

// Hooks
import { computed } from "vue";

// Types
import type { I_Button } from "./button.types.ts";

const props = withDefaults(defineProps<Partial<I_Button>>(), {
    color: "violet",
    theme: "light",
    variant: "basic",
    as: "button",
    loading: false,
});

const classes = computed(() => {
    if (props.variant === "unset" || props.variant === "skeleton") {
        return [
            "tiw__button",
            `tiw__button_theme-${props.theme}`,
            `tiw__button_variant-${props.variant}`,
            { tiw__button_loading: props.loading },
        ];
    }
    return [
        "tiw__button",
        `tiw__button_theme-${props.theme}`,
        `tiw__button_variant-${props.variant}`,
        `tiw__button_color-${props.color}`,
        { tiw__button_loading: props.loading },
    ];
});
</script>

<style lang="scss" src="./button.scss"></style>
