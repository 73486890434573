import { info } from "@/helpers/consoleColors";
import { useAnalyticsStore } from "@/stores/analytics/analytics";

export default function useMeasurementProtocol(step: string = "unknown"): Promise<void> | Promise<Response> {
    const {
        PROD_MODE,
        EVENT_NAME,
        CLIENT_ID,
        SESSION_ID,
        MEASUREMENT_PROTOCOL_URL,
    } = useAnalyticsStore();

    const FETCH_OPTIONS = {
        method: "POST",
        body: JSON.stringify({
            client_id: CLIENT_ID,
            events: [{
                name: EVENT_NAME,
                params: {
                    session_id: SESSION_ID,
                    step: step,
                },
            }],
        }),
    };

    console.log('%c[ANALYTICS] Measurement Protocol', info, JSON.parse(FETCH_OPTIONS.body));

    if (PROD_MODE) {
        return fetch(MEASUREMENT_PROTOCOL_URL, FETCH_OPTIONS);
    }

    return new Promise<void>((resolve) => resolve());
}
