<template>
    <aside :class="classes" v-bind="$attrs">
        <div v-if="TIWStore.modeIsPopup" class="tiw__backdrop" />
        <div class="tiw__wrapper">
            <slot />
        </div>
        <slot name="after-wrapper"></slot>
    </aside>
    <RolledStatus
        v-if="TIWStore.modeIsPopup"
        :header="OperatorExecutingStore.currentStatusMessages.header"
        :status="OperatorExecutingStore.status"
        :percent="OperatorExecutingStore.percent"
    />
</template>

<script lang="ts">
export default {
    name: "ContainerComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Container]
 * Обертка для модального окна
 */

// Hooks
import { computed } from "vue";
// import useExecutingStatus from "@/hooks/useExecutingStatus";

// Components
import RolledStatus from "@/components/rolledStatus/rolledStatus.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useOperatorExecutingStore } from "@/stores/operatorExecuting/operatorExecuting";

const TIWStore = useTIWStore();
const OperatorExecutingStore = useOperatorExecutingStore();

const classes = computed(() => {
    const classList: string[] = [
        "tiw",
        "tiw__container",
    ];

    if (TIWStore.modeIsEmbed) {
        classList.push("tiw_open");
        classList.push("tiw_embed");
    }

    if (TIWStore.modeIsPopup) {
        if (TIWStore.IS_CLOSE) classList.push("tiw_close");
        if (!TIWStore.IS_CLOSE) classList.push("tiw_open");
        if (TIWStore.IS_ROLL) classList.push("tiw_open_rollDown");
        if (TIWStore.IS_TRANSITION) classList.push("tiw_transition");
    }

    return classList;
});
</script>

<style lang="scss" src="./container.scss"></style>
