// Hooks
import { defineStore } from "pinia";
import { ref, computed } from "vue";

/**
 * * 🆂🆃🅾🆁🅴 [ANALYTICS STORE] STORE для аналитики
 * */
export const useAnalyticsStore = defineStore("AnalyticsStore", () => {
    const DEV_MODE = ref<boolean>(import.meta.env.DEV);
    const PROD_MODE = ref<boolean>(import.meta.env.PROD);
    const EVENT_NAME = ref<string>("breezy_widget_action");
    const CLIENT_ID = ref<string>("client_id");
    const SESSION_ID = ref<string>("session_id");
    const MEASUREMENT_ID = ref<string>("measurement_id");
    const API_SECRET = ref<string>("api_secret");

    const initClientId = () => {
        const cookieArray: string[] = document.cookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            const cookie: string = cookieArray[i].trim();
            if (cookie.startsWith('_ga=')) {
                const parts: string[] = cookie.split('.');
                if (parts.length === 4) {
                    CLIENT_ID.value = `${parts[2]}.${parts[3]}`;
                    break;
                }
            }
        }
    };

    const initSessionId = () => {
        const measurementIdForCookieName: string = String(MEASUREMENT_ID.value).replace(/^G-/, '');
        const cookieName: string = `_ga_${measurementIdForCookieName}=`;
        const cookies: string[] = document.cookie.split(';');

        for (const cookie of cookies) {
            if (cookie.trim().startsWith(cookieName)) {
                SESSION_ID.value = cookie.substring(cookieName.length)?.split('.')?.[2] ?? '';
                break;
            }
        }
    };

    const MEASUREMENT_PROTOCOL_URL = computed<string>(() => {
        return `https://www.google-analytics.com/mp/collect?measurement_id=${MEASUREMENT_ID.value}&api_secret=${API_SECRET.value}`;
    });

    return {
        initClientId,
        initSessionId,

        DEV_MODE,
        PROD_MODE,
        EVENT_NAME,
        CLIENT_ID,
        SESSION_ID,
        MEASUREMENT_ID,
        API_SECRET,

        MEASUREMENT_PROTOCOL_URL,
    };
});
