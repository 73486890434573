// Hooks
import apiGetWidget from "@/hooks/requests/apiGetWidget/apiGetWidget";
import useBrands from "@/hooks/useBrands/useBrands";
import useHelpCrunchChat from "@/hooks/useHelpCrunchChat";

// Data
import TRANSLATIONS from "@/helpers/translations";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useAnalyticsStore } from "@/stores/analytics/analytics";

export default async function initWidget(): Promise<void> {
    const TIWStore = useTIWStore();
    const AnalyticsStore = useAnalyticsStore();

    const widgetData = await apiGetWidget({
        domain: TIWStore.INJECTED_TIW.current_settings.domain,
    });

    widgetData.data.translations = TRANSLATIONS;

    AnalyticsStore.MEASUREMENT_ID = widgetData.data.data.measurement_id ?? AnalyticsStore.MEASUREMENT_ID;
    AnalyticsStore.API_SECRET = widgetData.data.data.api_secret ?? AnalyticsStore.API_SECRET;
    AnalyticsStore.initClientId();
    AnalyticsStore.initSessionId();

    TIWStore.WIDGET_DATA = widgetData.data;

    if (TIWStore.showHelpCrunchChat) {
        useHelpCrunchChat();
    }

    await useBrands();
}
