<template>
    <Modal>
        <template #header>
            <h2>
                {{ useTranslate("tiw__evaluationResult__title") }}
            </h2>
        </template>

        <div class="tiw__step__evaluationResult">
            <HandedDevice
                :device-chain="ChooseDeviceStore.deviceChain"
                :device-state="HowYouRateDeviceStore.rate"
                :device-preview="ChooseDeviceStore.deviceImagePreview"
                :device-grade="HowYouRateDeviceStore.deviceGrade"
                :device-no-grade="
                    EvaluationResultStore.stateOfEvaluation === 'UNKNOWN'
                "
                :device-terminated="
                    EvaluationResultStore.stateOfEvaluation === 'TERMINATED'
                "
                :on-edit="onEditHandler"
            />

            <template
                v-if="
                    EvaluationResultStore.stateOfEvaluation !== 'UNKNOWN' &&
                    EvaluationResultStore.stateOfEvaluation !== 'TERMINATED'
                "
            >
                <CalculatedDevice
                    :calculated-data="
                        HowYouRateDeviceStore.calculatedDeviceData
                    "
                />
            </template>

            <template
                v-if="EvaluationResultStore.stateOfEvaluation !== 'TERMINATED'"
            >
                <template
                    v-if="
                        EvaluationResultStore.stateOfEvaluation ===
                            'OPPORTUNITY' ||
                        EvaluationResultStore.stateOfEvaluation === 'UNKNOWN'
                    "
                >
                    <div class="tiw__step__evaluationResult__download-more">
                        <h3 class="tiw__step__evaluationResult__title">
                            {{
                                useTranslate(
                                    "tiw__evaluationResult__downloadMore"
                                )
                            }}
                        </h3>
                        <QRPanel :qr-preview="EvaluationResultStore.qrPath" />
                    </div>
                </template>

                <form
                    ref="FormRef"
                    class="tiw__step__evaluationResult__form"
                    name="downloadMorePhotosForm"
                >
                    <!-- <h3 class="tiw__step__evaluationResult__title">
                    Загрузить фотографии с этого устройства
                </h3> -->

                    <template
                        v-for="(
                            lineData, index
                        ) in EvaluationResultStore.fieldsLineData"
                        :key="EvaluationResultStore.fieldNamesData[index]"
                    >
                        <LoadPanel
                            :data="
                                EvaluationResultStore[
                                    EvaluationResultStore.fieldNamesData[index]
                                ]
                            "
                            name="file"
                            :name-data="
                                EvaluationResultStore.fieldNamesData[index]
                            "
                            :line-id="lineData.lineId"
                            :title="lineData.sideDescription"
                            :hint="lineData.text"
                            :is-loading-status="loadingToNext"
                            :on-load="onLoadHandler"
                            :on-remove="onRemoveHandler"
                        />
                    </template>
                </form>

                <Button
                    type="button"
                    class="tiw__step__evaluationResult__btn-next tiw__btn-next"
                    :disabled="loadingToNext"
                    :loading="loadingToNext"
                    @click="clickToNext"
                >
                    <span>
                        {{ useTranslate("tiw__continue") }}
                    </span>
                </Button>
            </template>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "EvaluationResult",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [EvaluationResult]
 * Модальное окно / экран "Результаты оценки"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import Button from "@/ui/button/button.vue";
import QRPanel from "@/components/qrPanel/qrPanel.vue";
import LoadPanel from "@/components/loadPanel/loadPanel.vue";
import HandedDevice from "@/components/handedDevice/handedDevice.vue";
import CalculatedDevice from "@/components/calculatedDevice/calculatedDevice.vue";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useOperatorExecutingStore } from "@/stores/operatorExecuting/operatorExecuting";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";

// Hooks
import { ref } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import usePhotoReloader from "@/hooks/usePhotoReloader/usePhotoReloader";
import useValidate from "@/hooks/useValidate";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import usePhotoSetStatus from "@/hooks/usePhotoSetStatus/usePhotoSetStatus";
import it4SetPhotoSetStatus from "@/hooks/requests/it4SetPhotoSetStatus/it4SetPhotoSetStatus";
import {
    useAnalyticsEventPhotoAssessment,
    useAnalyticsEventPersonalData,
    useAnalyticsEventTiModel,
} from "@/hooks/useAnalytics/useAnalytics";

// Types
import type { I_EvaluationResult_Field_Names } from "@/stores/evaluationResult/evaluationResult.types";
import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const DownloadPhotosStore = useDownloadPhotosStore();
const OperatorExecutingStore = useOperatorExecutingStore();
const EvaluationResultStore = useEvaluationResultStore();

const onEditHandler = () => {
    useStoreResetter();
    TIWStore.switchStep("chooseDevice");
    useAnalyticsEventTiModel();
};

const useFileValidator = (
    target: HTMLInputElement,
    fieldName: I_EvaluationResult_Field_Names
) => {
    const validateStatus = useValidate({ target: "file", value: target });

    if (!validateStatus.status) {
        EvaluationResultStore[fieldName].message = {
            type: "error",
            text: validateStatus.message,
        };
        return;
    } else {
        if (EvaluationResultStore[fieldName].message) {
            delete EvaluationResultStore[fieldName].message;
        }
    }

    EvaluationResultStore[fieldName].placeholder = target.files[0].name;
    EvaluationResultStore[fieldName].content = URL.createObjectURL(
        target.files[0]
    );
    EvaluationResultStore[fieldName].isValid = true;
};

// Слушатель и валидатор полей загрузки фотографий
const onLoadHandler = (
    e: Event & { target: HTMLInputElement },
    fieldName: I_EvaluationResult_Field_Names
) => {
    useFileValidator(e.target, fieldName);
};

const onRemoveHandler = (
    e: Event & { target: HTMLButtonElement },
    inputName: I_EvaluationResult_Field_Names
) => {
    EvaluationResultStore.removePhotoFromFiled(inputName);
};

const loadingToNext = ref<boolean>(false);
const FormRef = ref<HTMLFormElement>();
const isHaveQrUploadPhotos = ref<boolean>(false);

const clickToNext = async () => {
    loadingToNext.value = true;

    const photoSetStatus = await usePhotoSetStatus();

    if (!photoSetStatus) {
        useBugTracker("EvaluationResult - usePhotoSetStatus: photoSetStatus is wrong", BugTrackerSection.ERROR, {
            //
        })

        loadingToNext.value = false;
        return;
    }

    // Проверка на Дозагрузку фото
    if (
        EvaluationResultStore.stateOfEvaluation === "OPPORTUNITY" ||
        EvaluationResultStore.stateOfEvaluation === "UNKNOWN"
    ) {
        photoSetStatus.instructions.forEach((el) => {
            EvaluationResultStore.qrUploadChecker(el);

            if (el.wasUploadedAfterRemarks) {
                isHaveQrUploadPhotos.value = true;
            }
        });
    }

    /**
     * Срабатывание watch внутри EvaluationResultStore – это микротаска походу (но это не точно),
     * в связи с этим компутэд-значение EvaluationResultStore.isAcceptedToNext было
     * ещё старым (не пересчитанным), на момент работы синхронного кода.
     *
     * Мы создали микротаску, которая отработает ПОСЛЕ отработки WATCH внутри EvaluationResultStore,
     * и таким образом внутри синхронного кода мы будем иметь пересчитанное компутэд-значение
     *
     * @description Интересная статья по теме
     * @link https://habr.com/ru/companies/yandex/articles/718084/
     */
    queueMicrotask(async () => {
        // "GRADED" || "OPPORTUNITY" валидацию полей пропускаем
        // смотрим были ли загружены фото ранее через QR
        if (
            EvaluationResultStore.stateOfEvaluation === "GRADED" ||
            EvaluationResultStore.stateOfEvaluation === "OPPORTUNITY"
        ) {
            const isWasPhotoReload = await usePhotoReloader(FormRef.value);

            if (isWasPhotoReload || isHaveQrUploadPhotos.value) {
                console.log(
                    "ФОТО БУДУТ/БЫЛИ ЗАГРУЖЕНЫ -> ПЕРЕХОД К operatorExecuting"
                );

                if (isHaveQrUploadPhotos.value) {
                    // Открыть фотосет
                    try {
                        await it4SetPhotoSetStatus({
                            token: TIWStore.WIDGET_DATA.token,
                            lang: TIWStore.standardizedLocale,
                            deviceId: HowYouRateDeviceStore.registeringDeviceData .deviceId,
                            photosetId: DownloadPhotosStore.photoSetData.photosetId,
                            status: "TO_GRADING",
                        });
                    } catch (e) {
                        useBugTracker("EvaluationResult - it4SetPhotoSetStatus", BugTrackerSection.ERROR, {
                            exception: e.message,
                            request: {
                                token: TIWStore.WIDGET_DATA.token,
                                lang: TIWStore.standardizedLocale,
                                deviceId: HowYouRateDeviceStore.registeringDeviceData .deviceId,
                                photosetId: DownloadPhotosStore.photoSetData.photosetId,
                                status: "TO_GRADING",
                            },
                            stateOfEvaluation: EvaluationResultStore.stateOfEvaluation,
                        })
                    }
                }

                // Переключение экранов "Идёт изучение фотографий"
                OperatorExecutingStore.status = "WAITING";
                loadingToNext.value = false;
                TIWStore.switchStep("operatorExecuting");
                useAnalyticsEventPhotoAssessment();
            } else {
                loadingToNext.value = false;
                console.log("БЕЗ ЗАГРУЗОК -> ПЕРЕХОД К successfulEvaluation");

                try {
                    // Закрыть фотосет
                    await it4SetPhotoSetStatus({
                        token: TIWStore.WIDGET_DATA.token,
                        lang: TIWStore.standardizedLocale,
                        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                        photosetId: DownloadPhotosStore.photoSetData.photosetId,
                        status: "GRADED",
                    });
                } catch (e) {
                    useBugTracker("EvaluationResult - it4SetPhotoSetStatus", BugTrackerSection.ERROR, {
                        exception: e.message,
                        request: {
                            token: TIWStore.WIDGET_DATA.token,
                            lang: TIWStore.standardizedLocale,
                            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                            photosetId: DownloadPhotosStore.photoSetData.photosetId,
                            status: "GRADED",
                        },
                        stateOfEvaluation: EvaluationResultStore.stateOfEvaluation,
                    })
                }

                TIWStore.switchStep("successfulEvaluation");
                useAnalyticsEventPersonalData();
                return;
            }
        }

        if (EvaluationResultStore.stateOfEvaluation === "UNKNOWN") {
            // определить во всех ли нужных полях выбрано фото и если можно приступить к загрузить фото
            if (EvaluationResultStore.isAcceptedToNext) {
                console.log(
                    "ФОТО УСПЕШНО БУДУТ ЗАГРУЖЕНЫ -> ПЕРЕХОД К operatorExecuting"
                );

                const isWasPhotoReload = await usePhotoReloader(FormRef.value);

                if (!isWasPhotoReload) {
                    // Открыть фотосет
                    try {
                        await it4SetPhotoSetStatus({
                            token: TIWStore.WIDGET_DATA.token,
                            lang: TIWStore.standardizedLocale,
                            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                            photosetId: DownloadPhotosStore.photoSetData.photosetId,
                            status: "TO_GRADING",
                        });
                    } catch (e) {
                        useBugTracker("EvaluationResult - it4SetPhotoSetStatus", BugTrackerSection.ERROR, {
                            exception: e.message,
                            request: {
                                token: TIWStore.WIDGET_DATA.token,
                                lang: TIWStore.standardizedLocale,
                                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
                                photosetId: DownloadPhotosStore.photoSetData.photosetId,
                                status: "TO_GRADING",
                            },
                            stateOfEvaluation: EvaluationResultStore.stateOfEvaluation,
                        })
                    }
                }

                // Переключение экранов "Идёт изучение фотографий"
                OperatorExecutingStore.status = "WAITING";
                loadingToNext.value = false;
                TIWStore.switchStep("operatorExecuting");
                useAnalyticsEventPhotoAssessment();
                return;
            } else {
                EvaluationResultStore.fieldsLineData.forEach(
                    ({ lineId, status }) => {
                        if (
                            status === "BAD_QUALITY" ||
                            status === "IRRELEVANT"
                        ) {
                            useFileValidator(
                                FormRef.value[lineId],
                                FormRef.value[lineId].dataset.name
                            );
                        }
                    }
                );
                console.log("ЕСТЬ ПОЛЯ С БЕЗ ФОТО");
                loadingToNext.value = false;
                return;
            }
        }
    });
};
</script>

<style lang="scss" src="./evaluationResult.scss"></style>
