// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

// Types
import type { I_UseValidate_Return } from "../index";

/** Функция-валидатор для поля "ИМЕЙ" */
export default function imeiValidator(value: string): I_UseValidate_Return {
    if (!value) {
        return {
            status: false,
            message: useTranslate("tiw__validation__imei_required"),
        };
    }

    if (!/^\d{15,15}$/.test(value)) {
        return {
            status: false,
            message: useTranslate("tiw__validation__imei_range"),
        };
    }

    return {
        status: true,
        message: useTranslate("tiw__validation__success"),
    };
}
