<template>
    <Modal class="tiw__step__initWidgetLoader">
        <template #header>
            <div class="tiw__step__initWidgetLoader__body__header">
                <PlaceholderLoader width="354px" height="20px" />
                <PlaceholderLoader width="306px" height="20px" />
            </div>
        </template>

        <div class="tiw__step__initWidgetLoader__body">
            <div class="tiw__step__initWidgetLoader__body__form">
                <div v-for="i in 5" :key="i" class="tiw__step__initWidgetLoader__body__form-select">
                    <PlaceholderLoader
                        width="120px"
                        height="14px"
                    />
                    <PlaceholderLoader
                        width="400px"
                        height="40px"
                        block-class-list="tiw__step__initWidgetLoader__body__form-select__loader__block"
                    />
                </div>
            </div>

            <div class="tiw__step__initWidgetLoader__body__button">
                <PlaceholderLoader
                    width="129px"
                    height="40px"
                    class-list="tiw__step__initWidgetLoader__body__button__loader"
                    block-class-list="tiw__step__initWidgetLoader__body__button__loader__block"
                />
            </div>

            <div class="tiw__step__initWidgetLoader__body__footer">
                <PlaceholderLoader width="400px" height="16px" />
                <PlaceholderLoader
                    class-list="tiw__step__initWidgetLoader__body__footer__help-chat"
                    width="266px"
                    height="24px"
                />
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "InitWidgetLoader",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [InitWidgetLoader]
 * Лоадер при инициализации приложения
 */

// Components
import Modal from "@/components/modal/modal.vue";
import PlaceholderLoader from "@/components/placeholderLoader/placeholderLoader.vue";

//
</script>

<style lang="scss" src="./initWidgetLoader.scss"></style>
