<template>
    <template v-if="props.variant === 'circled'">
        <!-- * Иконка 'Закрыть / Крестик' на сером круглом фоне -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            :class="['tiw__icon']"
            v-bind="$attrs"
        >
            <rect width="24" height="24" rx="12" fill="#F4F6F8" />
            <path
                d="M8 8L16 16"
                stroke="#808D9A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8 16L16 8"
                stroke="#808D9A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </template>

    <!-- * Иконка 'Закрыть / Крестик' просто крестик -->
    <template v-if="props.variant === 'basic'">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            :class="['tiw__icon']"
            v-bind="$attrs"
        >
            <path
                d="M4 4L12.5161 12.5161"
                stroke="#808D9A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4 12.5161L12.5161 3.99998"
                stroke="#808D9A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </template>

    <!-- * Иконка 'Закрыть / Крестик' внутри круга с прозрачным фоном -->
    <template v-if="props.variant === 'outline'">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            :class="['tiw__icon']"
            v-bind="$attrs"
        >
            <g clip-path="url(#clip0_26953_46669)">
                <path
                    d="M10.0013 18.3346C14.6037 18.3346 18.3346 14.6037 18.3346 10.0013C18.3346 5.39893 14.6037 1.66797 10.0013 1.66797C5.39893 1.66797 1.66797 5.39893 1.66797 10.0013C1.66797 14.6037 5.39893 18.3346 10.0013 18.3346Z"
                    stroke="#F14459"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12.5 7.5L7.5 12.5"
                    stroke="#F14459"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.5 7.5L12.5 12.5"
                    stroke="#F14459"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_26953_46669">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </template>
</template>

<script lang="ts">
export default {
    name: "CloseIcon",
};
</script>

<script lang="ts" setup>
interface I_Props {
    variant?: "basic" | "circled" | "outline";
}

const props = withDefaults(defineProps<I_Props>(), {
    variant: "circled", // "circled" is default
});
</script>
