<template>
    <!-- * Иконка 'Анимированный лоадэр' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        width="200"
        height="200"
        style="
            shape-rendering: auto;
            display: block;
            background: rgb(255, 255, 255);
        "
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <g>
            <g transform="rotate(0 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.4375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(9 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(18 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.3125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(27 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.25s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(36 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.1875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(45 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(54 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2.0625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(63 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-2s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(72 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.9375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(81 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(90 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.8125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(99 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.75s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(108 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.6875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(117 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(126 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.5625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(135 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.5s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(144 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.4375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(153 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(162 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.3125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(171 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.25s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(180 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.1875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(189 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(198 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1.0625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(207 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-1s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(216 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.9375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(225 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(234 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.8125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(243 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.75s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(252 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.6875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(261 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(270 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.5625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(279 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.5s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(288 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.4375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(297 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.375s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(306 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.3125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(315 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.25s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(324 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.1875s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(333 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.125s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(342 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="-0.0625s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g transform="rotate(351 50 50)">
                <rect
                    fill="#6959cb"
                    height="15"
                    width="1"
                    ry="0"
                    rx="0"
                    y="2.5"
                    x="49.5"
                >
                    <animate
                        repeatCount="indefinite"
                        begin="0s"
                        dur="2.5s"
                        keyTimes="0;1"
                        values="1;0"
                        attributeName="opacity"
                    />
                </rect>
            </g>
            <g />
        </g>
        <!-- [ldio] generated by https://loading.io -->
    </svg>
</template>

<script lang="ts">
export default {
    name: "LoaderIcon",
};
</script>
