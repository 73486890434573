// Libs
import axios from "../configRequest";

// Hooks
import { info } from "@/helpers/consoleColors";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4RegisterProduct,
    I_it4RegisterProduct_Return,
} from "./it4RegisterProduct.types";

/**
 * Зарегистрировать продукт
 * 5.1 документация ru 1.0.5 (d1b82f5)
 * */
export const it4RegisterProduct: I_it4RegisterProduct = async (
    aboutProducts
) => {
    const registerData = await axios.post<I_it4RegisterProduct_Return["data"]>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/device`,
        {
            productId: aboutProducts.code,
            token: aboutProducts.token,
            lang: aboutProducts.lang,
            article: aboutProducts.article,
        },
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
    );
    console.log("%c[TIWidget] -> [DeviceID]", info, registerData.data.deviceId);
    return registerData.data;
};

export default it4RegisterProduct;
