// Libs
import axios from "../configRequest";

// Hooks
import { info } from "@/helpers/consoleColors";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4GetPhotoSetId,
    I_it4GetPhotoSetId_Return,
} from "./it4GetPhotoSetId.types.ts";

/**
 * Получить фотосет ID после загрузки фото в ИТ4 (он же и устанавливает статус на "TO_GRADING")
 * 15.3 документация ru 1.0.5 (d1b82f5)
 * */
export const it4getPhotoSetId: I_it4GetPhotoSetId = async (
    deviceId,
    payload
) => {
    const photoSetData = await axios.post<I_it4GetPhotoSetId_Return["data"]>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/photos/${deviceId}`,
        payload,
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }
    );
    console.log(
        "%c[TIWidget] -> [PhotosetID]",
        info,
        photoSetData.data.photosetId
    );
    console.log(
        "%c[TIWidget] -> [Photoset №]",
        info,
        photoSetData.data.photosetNumber
    );
    return photoSetData.data;
};

export default it4getPhotoSetId;
