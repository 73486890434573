<template>
    <!-- * Иконка 'Обновить' -->
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M3.23701 6.36784C3.58142 5.16677 4.29721 4.10556 5.28182 3.3363C6.26642 2.56705 7.46928 2.12924 8.71799 2.08564C9.96671 2.04203 11.1972 2.39486 12.233 3.09356C13.2689 3.79226 14.057 4.80096 14.4843 5.97508"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.3784 10.3014C13.9333 11.3563 13.1917 12.2593 12.2436 12.9011C11.2954 13.5429 10.1814 13.8957 9.03671 13.917C7.89199 13.9382 6.7657 13.6268 5.79443 13.0206C4.82315 12.4144 4.04862 11.5394 3.56476 10.5018"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.80859 9.95703L3.09729 10.2108L2.27353 11.7321"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.0898 6.48828L14.8011 6.23451L15.6249 4.71321"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "RefreshIcon",
};
</script>
