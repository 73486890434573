// Libs
import axios from "../configRequest.js";

// Hooks
import { info } from "@/helpers/consoleColors";

// Data
import { I_AXIOS } from "../configRequest.model.js";

// Types
import type {
    I_it4GetUploadId,
    I_it4GetUploadId_Return,
} from "./it4GetUploadId.types.js";

/**
 * Получить "uploadId" в ИТ4 для QR-кода
 * 17.1 документация ru 1.0.5 (d1b82f5)
 * */
export const it4GetUploadId: I_it4GetUploadId = async (payload) => {
    const uploadIdData = await axios.get<I_it4GetUploadId_Return["data"]>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/upload-id`,
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
            },
            params: payload,
        }
    );
    console.log(
        "%c[TIWidget] -> [Upload ID]",
        info,
        uploadIdData.data.uploadId
    );
    return uploadIdData.data;
};

export default it4GetUploadId;
