<template>
    <!-- * Иконка 'Вывеска и восклицательный знак в треугольнике' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M73.75 32.5H62.825L43.275 10.5375C43.575 10 43.75 9.4 43.75 8.75C43.75 6.6875 42.0625 5 40 5C37.9375 5 36.25 6.6875 36.25 8.75C36.25 9.4 36.4375 10.0125 36.725 10.5375L22.3625 26.6625L24.225 28.325L38.575 12.2125C39.0125 12.3875 39.4875 12.5 39.9875 12.5C40.4875 12.5 40.9625 12.4 41.4 12.2125L59.4625 32.5H20.525L24.225 28.325L22.3625 26.6625L17.1875 32.5125H6.25C4.1875 32.5125 2.5 34.2 2.5 36.2625V71.2625C2.5 73.325 4.1875 75.0125 6.25 75.0125H73.75C75.8125 75.0125 77.5 73.325 77.5 71.2625V36.25C77.5 34.1875 75.8125 32.5 73.75 32.5ZM40 10C39.3125 10 38.75 9.4375 38.75 8.75C38.75 8.0625 39.3125 7.5 40 7.5C40.6875 7.5 41.25 8.0625 41.25 8.75C41.25 9.4375 40.6875 10 40 10ZM75 71.25C75 71.9375 74.4375 72.5 73.75 72.5H6.25C5.5625 72.5 5 71.9375 5 71.25V36.25C5 35.5625 5.5625 35 6.25 35H14.95C16 35 17 35 18.3 35H61.7125C62.5 35 64.5 35 65.0625 35H73.7625C74.45 35 75.0125 35.5625 75.0125 36.25V71.25H75Z"
            fill="#808D9A"
        />
        <path
            d="M37.721 43.1469L26.4277 62.0003C26.1948 62.4035 26.0716 62.8607 26.0703 63.3263C26.069 63.7919 26.1897 64.2498 26.4202 64.6543C26.6508 65.0588 26.9833 65.3959 27.3846 65.6321C27.7859 65.8682 28.2421 65.9952 28.7077 66.0003H51.2943C51.7599 65.9952 52.2161 65.8682 52.6174 65.6321C53.0187 65.3959 53.3512 65.0588 53.5818 64.6543C53.8124 64.2498 53.933 63.7919 53.9317 63.3263C53.9304 62.8607 53.8072 62.4035 53.5743 62.0003L42.281 43.1469C42.0433 42.7551 41.7086 42.4311 41.3093 42.2063C40.9099 41.9814 40.4593 41.8633 40.001 41.8633C39.5427 41.8633 39.0921 41.9814 38.6927 42.2063C38.2934 42.4311 37.9587 42.7551 37.721 43.1469V43.1469Z"
            stroke="#6959CB"
            stroke-width="2.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M40 50V55.3333"
            stroke="#6959CB"
            stroke-width="2.4"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M40 60.668H40.0133"
            stroke="#6959CB"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "HandingIcon",
};
</script>
