// Helpers
import { dang } from "@/helpers/consoleColors";
import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

// Types
import type { I_apiImageConvert_ReturnData } from "@/hooks/requests/apiImageConvert/apiImageConvert.types";

// Hooks
import apiImageConvert from "@/hooks/requests/apiImageConvert/apiImageConvert";
import apiGetImageConverted from "@/hooks/requests/apiGetImageConverted/apiGetImageConverted";

// const REGEX_IMAGE_FORMAT_HEIC: RegExp = /\.heic$/i;
const REGEX_IMAGE_ALL_FORMATS: RegExp = /\.(png|jpeg|jpg|bmp)$/i;

export const isNeedConvertImageToJpeg = (target: HTMLInputElement): boolean => {
    if (target.files.length === 0) {
        return false;
    }

    const filename = target.files[0].name;

    return !REGEX_IMAGE_ALL_FORMATS.test(filename);
};

export const convertImageToJpeg = async (target: HTMLInputElement): Promise<void> => {
    if (target.files.length === 0) {
        return;
    }

    try {
        const prefixFilename: string = import.meta.env.VITE_PREFIX_IMAGE_CONVERTED;
        const targetFile = target.files[0];

        useBugTracker("convertImageToJpeg 1 (prepare file from input)", BugTrackerSection.INFO, {
            targetFile: {
                name: targetFile.name,
                size: targetFile.size,
                type: targetFile.type,
                lastModified: targetFile.lastModified,
            },
        });

        const formData: FormData = new FormData();
        formData.set("image", targetFile);

        const response: I_apiImageConvert_ReturnData = await apiImageConvert(formData);

        useBugTracker("convertImageToJpeg 2 (response converting)", BugTrackerSection.INFO, {
            response,
        });

        const blob: Blob = await apiGetImageConverted(response.link);

        useBugTracker("convertImageToJpeg 3 (prepare blob)", BugTrackerSection.INFO, {
            blob: {
                size: blob.size,
                type: blob.type,
            },
        });

        const filename: string = prefixFilename + targetFile.name.replace(/(\.\w*|)$/i, ".jpg")
        const file: File = new File([blob], filename, {
            type: blob.type,
        });

        useBugTracker("convertImageToJpeg 4 (prepare converted file for input)", BugTrackerSection.INFO, {
            filename,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified,
            },
            fileAsString: file.toString(),
        });

        const dataTransfer: DataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        target.files = dataTransfer.files;
    } catch (e) {
        console.log("%c[convertImageToJpeg - catch]", dang, e);

        useBugTracker("convertImageToJpeg", BugTrackerSection.ERROR, {
            error: e.toString(),
        });
    }
};
